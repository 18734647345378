const iconClassName = "social-icon"

export const SocialIconLink = ({children, url=""}) => {
  if (url !== "") {
    return (<a href={url} target="_blank" rel="noopener noreferrer">{children}</a>);
  } else {
    return (<>{children}</>);
  }
}

export const CapitalIQIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="Capital IQ" src="/social_icons/capitaliq-icon.svg" /></SocialIconLink>
)

export const CapterraIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="Capterra" src="/social_icons/capterra-icon.svg" /></SocialIconLink>
)

export const CrunchbaseIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="Crunchbase" src="/social_icons/crunchbase-icon.svg" /></SocialIconLink>
)

export const GartnerIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="Gartner" src="/social_icons/gartner-icon.svg" /></SocialIconLink>
)

export const G2Icon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="G2" src="/social_icons/g2-icon.svg" /></SocialIconLink>
)

export const GlassdoorIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="Glassdoor" src="/social_icons/glassdoor-icon.svg" /></SocialIconLink>
)

export const GoogleIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="Google" src="/social_icons/google-icon.svg" /></SocialIconLink>
)

export const LinkedinIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="LinkedIn" src="/social_icons/linkedin-icon.svg" /></SocialIconLink>
)

export const NewsAPIIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="News API" src="/social_icons/newsapi-icon.svg" /></SocialIconLink>
)

export const OpenAIIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="OpenAI" src="/social_icons/openai-icon.svg" /></SocialIconLink>
)

export const PitchbookIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="PitchBook" src="/social_icons/pitchbook-icon.svg" /></SocialIconLink>
)

export const QuanteraIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="Quantera" src="/social_icons/quantera-icon.svg" /></SocialIconLink>
)

export const RSSIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="RSS" src="/social_icons/rss-icon.svg" /></SocialIconLink>
)

export const SourcescrubIcon = ({url=""}) => (
  <SocialIconLink url={url}><img className={iconClassName} alt="SourceScrub" src="/social_icons/sourcescrub-icon.svg" /></SocialIconLink>
)
