import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import Card from 'react-bootstrap/Card';
import './ReportContainer.css';

const ReportContainer = ({children}) => {

  const reportRef = useRef(null);

  useEffect(() => {
    const wrapTablesWithDiv = (content) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;

      const tables = tempDiv.getElementsByTagName('table');
      for (let i = 0; i < tables.length; i++) {
        const table = tables[i];
        const wrapperDiv = document.createElement('div');
        wrapperDiv.className = 'table-container';
        table.parentNode.replaceChild(wrapperDiv, table);
        wrapperDiv.appendChild(table);
      }
      return tempDiv.innerHTML;
    };

    const addBlankTargetToLinks = (content) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;

      const links = tempDiv.getElementsByTagName('a');
      for (let link of links) {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer'); // rel="noopener noreferrer" for security
      }
      return tempDiv.innerHTML;
    };

    if (reportRef.current) {
      let content = reportRef.current.innerHTML;
      content = wrapTablesWithDiv(content);
      content = addBlankTargetToLinks(content);
      reportRef.current.innerHTML = content;
    }
  }, [children]);

    return (
        <Card>
            <Card.Body>
                <div id="report-container" ref={reportRef}>
                    <ReactMarkdown remarkPlugins={[gfm]}>
                        {children}
                    </ReactMarkdown>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ReportContainer;