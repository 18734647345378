// A modal used to display a small model with a confirmation button to carry out an event
import React, {useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";

function InfoPopupIcon({ infoText, title = "" }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="unstyled" className="py-0 my-0" onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faCircleInfo} />
      </Button>
      <Modal className="d-flex align-items-center" size="md" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h6">{title || "Info"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="small">
          {infoText}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InfoPopupIcon;
