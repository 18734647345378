import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {throttle} from "lodash";

const SidebarNavigation = ({ sections, className }) => {
    // sections is an object with keys: ref, label, icon and conditional
    const [activeSection, setActiveSection] = useState('');

    function getFullOffsetTop(element) {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    const scrollToSection = (section) => {
        const sectionRef = sections[section];
        if (sectionRef.ref?.current) {
            const fullOffsetTop = getFullOffsetTop(sectionRef.ref?.current);
            window.scrollTo({
                top: fullOffsetTop,
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        const onScroll = () => {
            let currentSection = '';

            Object.keys(sections).forEach((section) => {
                const ref = sections[section].ref?.current;
                const fullOffsetTop = getFullOffsetTop(ref);
                const offset = window.scrollY + 50; // Section starts 50px above actual ref position
                if (ref && fullOffsetTop <= offset && fullOffsetTop + ref.offsetHeight > offset) {
                    currentSection = section;
                }
            });

            if (currentSection !== activeSection) {
                setActiveSection(currentSection);
            }
        };

        const throttledOnScroll = throttle(onScroll, 100); // Throttling scroll events to fire every 100ms
        window.addEventListener('scroll', throttledOnScroll);
        return () => window.removeEventListener('scroll', throttledOnScroll);
    }, [activeSection, sections]);

    return (
        <ul className={`nav nav-pills square hover-grey dark flex-column mb-auto ${className}`}>
            {Object.keys(sections).map((key) => (
              (sections[key].conditional === undefined || sections[key].conditional) && (
                <li key={key} className="nav-item">
                    <Button variant="unstyled"
                            className={`nav-link d-block w-100 text-center text-md-start ${activeSection === key ? 'active' : ''}`}
                            onClick={() => scrollToSection(key)}>
                        <FontAwesomeIcon icon={sections[key].icon} />
                        <span className="d-none d-md-inline-block ms-2">{sections[key].label}</span>
                    </Button>
                </li>
              )
            ))}
        </ul>
    );
};

export default SidebarNavigation;