import React, { useEffect } from 'react';

const CtrlSaveListener = ({ saveButtonRef, onSaveHandler }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        if (saveButtonRef.current) {
          saveButtonRef.current.click();
        } else if (onSaveHandler) {
          onSaveHandler();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [saveButtonRef, onSaveHandler]);

  return null; // This component does not render anything
};

export default CtrlSaveListener;