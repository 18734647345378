import './CardShimmer.css';

const CardShimmer = ({widthPercent=100, heightPixels=100, paddingPercent=10, lines=1}) => {
  const heightPixelsPerLine = heightPixels / lines;
  const paddingPixels = heightPixelsPerLine * paddingPercent / 100;
  const squareSizePixels = heightPixelsPerLine - 2 * paddingPixels;
  const borderRadius = squareSizePixels * 0.05;

  const SingleItem = () => (
    <div className="line-container d-flex align-items-center" style={{
      padding: `${paddingPixels}px 0`,
    }}>
        <div className="square-left" style={{
          height: `${squareSizePixels}px`,
          width: `${squareSizePixels}px`,
          borderRadius: `${borderRadius}px`
        }}></div>
        <div className="rectangles-right flex-fill-horizontal" style={{
          marginLeft: `${paddingPixels}px`,
        }}>
          <div className="rectangle-right" style={{
            height: `${(squareSizePixels - paddingPixels) / 2}px`,
            marginBottom: `${paddingPixels}px`,
            borderRadius: `${borderRadius}px`
          }}></div>
          <div className="rectangle-right" style={{
            height: `${(squareSizePixels - paddingPixels) / 2}px`,
            borderRadius: `${borderRadius}px`
          }}></div>
        </div>
      </div>
  );

  return (
    <div className="card-shimmer" style={{
      height: `${heightPixels}px`,
      width: `${widthPercent}%`,
      padding: `0 ${paddingPixels}px`
    }}>
      {Array.from({ length: lines }, (_, index) => (
        <SingleItem key={index} />
      ))}
    </div>
  )
}

export default CardShimmer;