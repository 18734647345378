import './CardShimmer.css';

const RectangleShimmer = ({widthPixels=100, heightPixels=100, widthPercent=null, className=""}) => {
  let widthString;
  if (widthPercent !== null) {
    widthString = `${widthPercent}%`;
  } else {
    widthString = `${widthPixels}px`;
  }

  return (
    <div className={`rectangle-shimmer ${className}`} style={{
      height: `${heightPixels}px`,
      width: `${widthString}`,
    }}>
    </div>
  )
}

export default RectangleShimmer;