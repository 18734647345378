import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import GenericModal from "../GenericModal/GenericModal";
import ReactMarkdown from "react-markdown";
import {initializeEmailJob, startGenerateEmailStream, stopGenerateEmailStream} from "../../services/emails";
import Button from "react-bootstrap/Button";
import { getEmailTemplates, queryJob } from "../../services/emails";
import { getReportList } from "../../services/reports";
import InlineGrower from "../LoadingAnimations/InlineGrower";
import {faArrowRight, faArrowsRotate, faForward} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuanteraLoader from "../LoadingAnimations/QuanteraLoader";
import Alert from "react-bootstrap/Alert";
import Combobox from "../FormFields/Combobox";

const EmailGeneratorModal = ({ show, onHide, companyId, dealId, newsId = null, companyName="company"}) => {
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [reportOptions, setReportOptions] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState("");
  const [requiredContextInputs, setRequiredContextInputs] = useState({});
  const [emailContent, setEmailContent] = useState("");
  const [emailId, setEmailId] = useState("");
  const [initializingEmail, setInitializingEmail] = useState(false);
  const [jobId, setJobId] = useState("");
  const [generatingEmail, setGeneratingEmail] = useState(false);
  const [generationComplete, setGenerationComplete] = useState(false);
  const [generationTruncated, setGenerationTruncated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingStatusMessage, setLoadingStatusMessage] = useState("🚀 Initializing...");
  const emailBottomRef = useRef(null); // Creating a ref to scroll to bottom of email
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTemplates = async () => {
      let newsEventTemplatesOnly = false
      if (newsId) newsEventTemplatesOnly = true;
      const excludeNewsEventTemplates = !newsEventTemplatesOnly;

      try {
        const response = await getEmailTemplates(newsEventTemplatesOnly, excludeNewsEventTemplates);
        const templates = response.templates.map((template) => ({
          label: template.name,
          value: template.id,
          context_inputs: template.context_inputs
        }));
        setTemplateOptions(templates);
        // if (templates.length > 0) {
        //   setSelectedTemplateId(templates[0].value); // Set the default layout to the first layout in the list of options
        // }
      } catch (error) {
        console.log(error);
      }
    };
    if (show) fetchTemplates();
  }, [newsId, show]
  );

  useEffect(() => {
    const fetchReportList = async () => {
      try {
        const response = await getReportList({page: 1, per_page: 10, deal_id: dealId});
        const reports = response.reports.map((report) => ({
          label: `${report.title} - ${report.date}`,
          value: report.id,
        }));
        setReportOptions(reports);
        // if (reports.length > 0) {
        //   setSelectedReportId(reports[0].value); // Set the default layout to the first layout in the list of options
        // }
      } catch (error) {
        console.log(error);
      }
    }
    if (show && requiredContextInputs.report === true) fetchReportList();
  }, [show, requiredContextInputs]);

  const getContextInputsByTemplateId = (templates, templateId) => {
    const template = templates.find(template => template.value === templateId);
    return template ? template.context_inputs : [];
  }

  useEffect(() => {
    if (selectedTemplateId) {
      const contextInputs = getContextInputsByTemplateId(templateOptions, selectedTemplateId);
      setRequiredContextInputs(contextInputs);
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    // Scroll to the bottom whenever emailContent changes
    if (emailBottomRef.current) {
      emailBottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [emailContent]);

  const handleInitializeEmail = async () => {
    // First we run the API call to initialize the Job
    if (!selectedTemplateId) {
      setErrorMessage("You must select an email template");
      return
    }

    setEmailId("");
    setJobId("");
    setErrorMessage("");
    setInitializingEmail(true);
    const data = {
      'email_template_id': selectedTemplateId,
      'company_id': companyId,
      'report_id': selectedReportId,
      'news_id': newsId
    }
    try {
      const response = await initializeEmailJob(data);
      setJobId(response.job_id);
    } catch (error) {
      setErrorMessage(error.message);
      setInitializingEmail(false);
    }
  }

  // Once we've received a Job ID we want to keep pinging the job endpoint to check when it is completed
  const checkJobStatus = async () => {
    try {
      const response = await queryJob(jobId)
      if (response.status === "success") {
        setInitializingEmail(false);
        handleGenerateEmail();
      } else if (response.status === "failure") {
        setInitializingEmail(false);
        let error = "Email generator failed when building LLM context"
        if (response.failure_message) error = `${error} with the following message - ${response.failure_message}`
        setErrorMessage(error);
      } else {
        const lastMessage = response.messages.at(-1);
        setLoadingStatusMessage(lastMessage);
        setTimeout(checkJobStatus, 3000); // Recursively call checkJobStatus after 3 seconds
      }
    } catch (error) {
      setInitializingEmail(false);
      setErrorMessage(error.message);
    }
  }

  useEffect(() => {
    if (jobId) {
      setTimeout(checkJobStatus, 1000); // Initial call to checkJobStatus
    }
  }, [jobId])

  const handleGenerateEmail = () => {
    setGeneratingEmail(true);
    const onMessage = (newPart) => {
      if (emailId === "") {
        setEmailId(newPart.email_id);
      }
      if (newPart.completed === true) {
        stopGenerateEmailStream();
        setGeneratingEmail(false);
        if (newPart.truncated === true) {
          setGenerationTruncated(true);
        } else {
          setGenerationTruncated(false);
          setGenerationComplete(true);
        }
      }
      setEmailContent((prev) => prev + newPart.content);
    };

    const onError = () => {
      console.error('Error during SSE');
      setGeneratingEmail(false);
    };

    startGenerateEmailStream(dealId, jobId, selectedTemplateId, selectedReportId, emailId, onMessage, onError);
  };

  useEffect(() => {
    if (!generatingEmail && generationTruncated) handleGenerateEmail()
    if (generationComplete) {
      setJobId("");
    }
  }, [generatingEmail]);

  const clearData = () => {
    // Revert values back to defaults
    setEmailContent("");
    setJobId("");
    setEmailId("");
    setInitializingEmail(false);
    setGeneratingEmail(false);
    setGenerationComplete(false);
    setGenerationTruncated(false);
    setErrorMessage("");
    setLoadingStatusMessage("🚀 Initializing...");
  }

  return (
    <GenericModal size="xl" show={show} onHide={onHide} title={`Generate${ newsId ? (` news-related`) : '' } email to ${companyName}`}>
      <h6>Instructions</h6>
      <ol className="mb-4">
        <li>Select an email template (<Link to="/settings?tab=email-templates">click here</Link> to manage templates).</li>
        <li>If asked, select a report to use as an input.</li>
        <li>Click <kbd>Generate Email</kbd>. The algorithm will run real-time web and news searches before writing the email.</li>
      </ol>

      <div className="d-flex align-items-center mb-3">
        <div className="fw-semibold me-3" style={{width: "100px"}}>Template:</div>
        <Combobox label="Email Template" options={templateOptions} selectedItem={selectedTemplateId} setSelectedItem={setSelectedTemplateId} searchPlaceholder="Filter templates..." />
      </div>

      { requiredContextInputs && requiredContextInputs.report === true && (
        <div className="d-flex align-items-center mb-3">
          <div className="fw-semibold me-3" style={{width: "100px"}}>Report:</div>
          <Combobox label="Report" options={reportOptions} selectedItem={selectedReportId} setSelectedItem={setSelectedReportId} />
        </div>
      ) }

      { (!initializingEmail && !generatingEmail && !generationComplete && !generationTruncated) && (
        <Button variant="dark" onClick={() => handleInitializeEmail()}>Generate Email</Button>
      )}
      {errorMessage && (
        <div className="mt-3">
            <Alert className="py-2 d-inline-block" variant="danger">{errorMessage}</Alert>
        </div>
      )}
      { initializingEmail && (
        <div className="text-start">
          <QuanteraLoader /> <strong>{loadingStatusMessage}</strong>
        </div>
      )}
      <div className="generated-email-container">
        <ReactMarkdown>{emailContent}</ReactMarkdown>
        { !initializingEmail && generatingEmail && (
          <>
            <InlineGrower />
          </>
        )}
      </div>
      { (!generatingEmail && generationTruncated) && (
        <Button variant="outline-dark" onClick={() => handleGenerateEmail()}>
          <FontAwesomeIcon icon={faForward} className="me-1"/> Continue Generating
        </Button>
      )}
      { generationComplete && (
        <>
          <Button onClick={() => navigate(`/email/${emailId}`)} variant="dark" className="me-3">
            <FontAwesomeIcon icon={faArrowRight} className="me-1" /> Go to Email
          </Button>
          <Button onClick={() => clearData()} variant="outline-dark" className="me-3">
            <FontAwesomeIcon icon={faArrowsRotate} className="me-1" /> Start Again
          </Button>
        </>
      )}
      <div ref={emailBottomRef}></div>
    </GenericModal>
  )
};

export default EmailGeneratorModal;