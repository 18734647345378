import React from 'react';
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const NotFoundPage = () => {
    return (
        <PageWrapper title="404">
            Page not found.
        </PageWrapper>
    )
}

export default NotFoundPage;