import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getDealList, deleteDeal, getDealSearchResults } from '../../services/companies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import IconTooltipButton from "../Buttons/IconTooltipButton";
import DealIcon from "../DealIcon/DealIcon";
import './DealsTable.css';
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import DisappearingToast from "../DisappearingToast/DisappearingToast";
import GenericTable from "../GenericTable/GenericTable";

const DealsTable = (
  {
    pageNum=1,
    dealsPerPage = 10,
    searchString="",
    cardFooter=false
  }) => {
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [dealData, setDealData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [dealIdToDelete, setDealIdToDelete] = useState("");
  const [dealNameToDelete, setDealNameToDelete] = useState("");
  const [showDeletedToast, setShowDeletedToast] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      if(searchString) {
        // If a search query has been submitted, ping the endpoint for search results
        const response = await getDealSearchResults({page: currentPage, per_page: dealsPerPage, query: searchString});
        setDealData(response);
      } else {
        // Otherwise ping a different endpoint for the full report list
        const response = await getDealList({page: currentPage, per_page: dealsPerPage});
        setDealData(response);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, searchString]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setCurrentPage(pageNum);
  }, [searchString]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteDeal = async (dealId) => {
    try {
      await deleteDeal(dealId);
      // Deal deleted successfully, refetch report list
      setShowDeletedToast(true);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const total_pages = dealData?.total_pages || 1;
  const current_page = dealData?.current_page || 1;
  const deals = dealData?.deals || [];

  const columns = [
    {
      header: 'Name',
      field: 'name',
      render: (deal) => (
        <div className="d-flex align-items-center">
          <Link to={`/deal/${deal.id}`}>
            <DealIcon alt={`${deal.name} logo`} icon={deal.icon} sizePixels={48} className="deals-table-icon me-3 flex-shrink-0" />
          </Link>
          <div className="flex-fill-horizontal">
            <Link to={`/deal/${deal.id}`}>{deal.name}</Link><br />
            <span className="small">{deal.identifier}</span>
          </div>
        </div>
      )
    },
    {
      header: 'Date Created',
      field: 'date_created'
    },
    {
      header: 'Date Updated',
      field: 'date_updated'
    },
    {
      header: 'Delete',
      field: 'delete',
      className: 'text-center',
      render: (deal) => (
        <IconTooltipButton tooltipText="Delete"
                           onClick={() => {setDealIdToDelete(deal.id); setDealNameToDelete(deal.name); setShowConfirmationModal(true);}}
                           className="py-1 px-2">
          <FontAwesomeIcon icon={faTrashCan} className="small" />
        </IconTooltipButton>
    )},
  ];

  return (
    <>
      <GenericTable
        columns={columns}
        data={deals}
        page={current_page}
        totalPages={total_pages}
        onChangePage={handlePageChange}
        isLoading={!dealData}
        footerClassName={cardFooter ? ("card-footer") : ("")}
      />
      <ConfirmationModal show={showConfirmationModal}
                         title="Confirm deletion"
                         body="This action will delete the deal along with all associated reports and emails. Are you sure you wish to proceed?"
                         onHide={() => setShowConfirmationModal(false)}
                         onConfirm={() => handleDeleteDeal(dealIdToDelete)}
      />
      <DisappearingToast title="Deleted" text={`Deleted: ${dealNameToDelete}`} show={showDeletedToast} setShow={setShowDeletedToast} />
    </>
  );
};

export default DealsTable;