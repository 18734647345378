import React, { useEffect, forwardRef, useState } from "react";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  toolbarPlugin,
  linkDialogPlugin,
  linkPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  DiffSourceToggleWrapper,
  diffSourcePlugin,
  tablePlugin,
  InsertTable,
  Separator,
  BlockTypeSelect,
  imagePlugin,
  InsertImage
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import './MarkdownEditor.css';
import {uploadImage} from "../../services/files";

const MarkdownEditor = forwardRef((
  {
    showBlockType=false,
    showTables=false,
    showImages=false,
    ...props
  }, ref
) => {
  const [shouldUpdate, setShouldUpdate] = useState(true);

  // set shouldUpdate => true on initial render, triggering re-render - forces the ref to get assigned
  useEffect(() => {
    if (shouldUpdate) setShouldUpdate(false)
  }, [shouldUpdate])

  useEffect(() => {
    // Set spellcheck attribute to false
    const editableElement = document.querySelector('.mdxeditor-contenteditable');
    if (editableElement) {
      editableElement.setAttribute('spellcheck', 'false');
    }
  }, [])

  const imageUploadHandler = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    try {
      const response = await uploadImage(formData);
      return response.url;
    } catch (error) {
      console.log(error);
    }
  }

  const plugins = [
    toolbarPlugin({
      toolbarContents: () => (
        <>
          {' '}
          <DiffSourceToggleWrapper>
            <UndoRedo/>
            <Separator/>
            <BoldItalicUnderlineToggles/>
            <Separator/>
            <ListsToggle/>
            <Separator/>
            { showBlockType && (
              <>
                <BlockTypeSelect/>
                <Separator/>
              </>
            ) }
            <CreateLink/>
            { showTables && (<InsertTable/>) }
            { showImages && (<InsertImage/>) }
          </DiffSourceToggleWrapper>
        </>
      )
    }),
    headingsPlugin(),
    listsPlugin(),
    linkDialogPlugin(),
    linkPlugin(),
    diffSourcePlugin({viewMode: 'rich-text', diffMarkdown: props.diffMarkdown, readOnlyDiff: true}),
  ];

  // Conditionally add the tablePlugin if showTables is true
  if (showTables) {
    plugins.push(tablePlugin());
  }

  if (showImages) {
    plugins.push(imagePlugin({ imageUploadHandler, disableImageResize: true, disableImageSettingsButton: true }));
  }

  return (
    <MDXEditor ref={ref} markdown={''} plugins={plugins} contentEditableClassName="mdxeditor-contenteditable" />
  );
});

export default MarkdownEditor;