import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { debounce } from 'lodash';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { faXmark, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SearchDealsInput.css';
import { getDealSearchResults } from "../../services/companies";
import ListGroup from "react-bootstrap/ListGroup";
import CardShimmer from "../LoadingAnimations/CardShimmer";
import DealIcon from "../DealIcon/DealIcon";

const SearchDealsInput = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [dealData, setDealData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [focusedItem, setFocusedItem] = useState(-1);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const searchBoxRef = useRef(null);

  const fetchData = async () => {
    if (searchQuery) {
      const response = await getDealSearchResults({ page: 1, per_page: 5, query: searchQuery });
      const { deals } = response;
      setDealData(deals);
    } else {
      setDealData([]);
    }
    setSearching(false);
  };

  const debouncedFetchData = debounce(fetchData, 300);

  useEffect(() => {
    if (!searchQuery) setFocusedItem(-1);
    if (!searching) setSearching(true);
    debouncedFetchData();
    return () => debouncedFetchData.cancel();
  }, [searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
        setSearchQuery("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault(); // Prevent scrolling the page
      setFocusedItem((prev) => (prev < dealData.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedItem((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === 'Enter' && dealData.length > 0) {
      setFocusedItem(-1);
      navigate(`/deal/${dealData[focusedItem].id}`);
    } else if (e.key === 'Escape') {
      setFocusedItem(-1);
      setSearchQuery("");
    }
  };

  return (
    <>
      <div ref={searchBoxRef} className="search-box">
        <Form className="search-form" onSubmit={(e) => { e.preventDefault(); navigate(`/deals?search=${searchQuery}`) }}>
          <Form.Control ref={inputRef} type="text" placeholder="Search Deals..." value={searchQuery} id="deal-search-input" onChange={(e) => setSearchQuery(e.target.value)} autoComplete="off" onKeyDown={handleKeyDown} />
          {searchQuery && (
            <Button variant="link" onClick={() => setSearchQuery("")} className="search-btn search-btn-cross"><FontAwesomeIcon className="small" icon={faXmark} /></Button>
          )}
          <Button variant="link" onClick={() => navigate(`/deals?search=${searchQuery}`)} className="search-btn search-btn-search"><FontAwesomeIcon className="small" icon={faSearch} /></Button>
        </Form>
        {searchQuery && (
          <div className="search-box-results small">
            <div className="results-list">
              {dealData.length !== 0 ? (
                <ListGroup className="list-group-flush search-list">
                  {dealData.map((deal, index) => (
                    <Button key={deal.id} variant="search-result" onClick={() => navigate(`/deal/${deal.id}`)} className="text-start">
                      <ListGroup.Item className={`d-flex align-items-center clearfix ${index === focusedItem ? 'focused' : ''}`}>
                        <DealIcon alt={`${deal.name} logo`} icon={deal.icon} sizePixels={48} className="deal-logo me-3 flex-shrink-0" />
                        <div className="flex-fill-horizontal">
                          <strong className="small">{deal.name}</strong><br />
                          <span className="small">{deal.identifier}</span>
                        </div>
                      </ListGroup.Item>
                    </Button>
                  ))}
                </ListGroup>
              ) : searching ? (
                <div><CardShimmer heightPixels={64} lines={1} paddingPercent={15} /></div>
              ) : (
                <div className="my-3 mx-3">No results</div>
              )}
            </div>
            <div className="results-divider small px-3 py-2">See all results by hitting ENTER or clicking on <FontAwesomeIcon className="ms-1" icon={faSearch} /></div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchDealsInput;
