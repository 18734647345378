import React, { forwardRef } from 'react';
import Form from "react-bootstrap/Form";
import './FormFields.css';

const FloatingTextAreaField = forwardRef(({ label, value, id, onChange, className="", isDisabled = false, autoComplete="off", height="80px" }, ref) => {
  return (
    <Form.Group className={`form-floating ${className}`}>
      <Form.Control ref={ref} as="textarea" style={{height: height}} placeholder={label} value={value} id={id} onChange={onChange} disabled={isDisabled} autoComplete={autoComplete} />
      <Form.Label htmlFor={id}>{label}</Form.Label>
    </Form.Group>
  );
});

export default FloatingTextAreaField;
