import React from 'react';
import './Footer.css';
import Container from "react-bootstrap/Container";

const Footer = () => {
  return (
    <footer id="sticky-footer" className="flex-shrink-0 py-3 bg-dark text-white-50">
      <Container className="text-center fw-light">
        <small>© 2023 Quantera Ltd. All rights reserved.</small>
      </Container>
    </footer>
  );
};

export default Footer;