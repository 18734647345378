import React, { useState, useEffect } from 'react'
import Col from "react-bootstrap/Col";
import {
  CapterraIcon,
  CrunchbaseIcon,
  G2Icon,
  GartnerIcon,
  GlassdoorIcon,
  LinkedinIcon,
  PitchbookIcon,
  SourcescrubIcon
} from "../SocialIcons/SocialIcons";
import './CompanyAboutCard.css';
import { getCompanyAbout } from "../../services/companies";
import Row from "react-bootstrap/Row";
import DealIcon from "../DealIcon/DealIcon";

const CompanyAboutCard = ({companyId, showHeader=true}) => {
  const [companyData, setCompanyData] = useState({});
  const [colWidth, setColWidth] = useState(6);

  const fetchData = async () => {
    const response = await getCompanyAbout(companyId);
    setCompanyData(response);
    if (!response.description) {
      setColWidth(12);
    }
  }

  const removeHttp = (url) => {
    return url.replace(/^https?:\/\//, '');
  }

  const addHttp = (url) => {
    // Check if the URL already has a scheme (http or https)
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { style: 'decimal' }).format(num);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Row className="company-about-card">
      { showHeader && (
        <>
          <div className="deal-title fs-6"><h6>{companyData.name}</h6></div>
          <hr />
        </>
      )}
      { companyData.description && (
        <Col sm={12} lg={6} className="px-2 mb-3">
            <div className="clearfix pe-1">
                <DealIcon alt={`${companyData.name} logo`} icon={companyData.icon} sizePixels={110} className="company-about-logo me-3 mb-2 float-start" />
                <div className="mt-1">{companyData.description}</div>
            </div>
        </Col>
      )}
      <Col sm={12} lg={colWidth} className="px-2 mb-3">
          <div className="css-table deal-about-table">
            { !companyData.description && (
              <DealIcon alt={`${companyData.name} logo`} icon={companyData.icon} sizePixels={110} className="company-about-logo ms-3 mb-3 float-end" />
            )}
            <div className="css-table-body">
                { companyData.name && (
                  <div className="css-table-row">
                      <div><strong>Name</strong></div>
                      <div>{companyData.name}</div>
                  </div>
                )}
                { companyData.legal_name && (
                  <div className="css-table-row">
                      <div><strong>Legal Name</strong></div>
                      <div>{companyData.legal_name}</div>
                  </div>
                )}
                { companyData.website_url && (
                  <div className="css-table-row">
                      <div><strong>Website</strong></div>
                      <div><a href={addHttp(companyData.website_url)} target="_blank" rel="noopener noreferrer">{removeHttp(companyData.website_url)}</a></div>
                  </div>
                )}
                { companyData.year_founded && (
                  <div className="css-table-row">
                      <div><strong>Founded</strong></div>
                      <div>{companyData.year_founded}</div>
                  </div>
                )}
                { companyData.employees && (
                  <div className="css-table-row">
                      <div><strong>Employees</strong></div>
                      <div>{formatNumber(companyData.employees)}</div>
                  </div>
                )}
                { (companyData.hq_city || companyData.hq_country) && (
                  <div className="css-table-row">
                      <div><strong>HQ</strong></div>
                      <div>
                        {
                          companyData.hq_city && companyData.hq_country ?
                          `${companyData.hq_city}, ${companyData.hq_country}` :
                          companyData.hq_city ? `${companyData.hq_city}` : `${companyData.hq_country}`
                        }
                      </div>
                  </div>
                )}
            </div>
          </div>
          <div className="mt-2 deal-socials">
              {companyData.pitchbook_url && ( <PitchbookIcon url={`${companyData.pitchbook_url}`} /> )}
              {companyData.crunchbase_url && ( <CrunchbaseIcon url={`${companyData.crunchbase_url}`} /> )}
              {companyData.sourcescrub_url && ( <SourcescrubIcon url={`${companyData.sourcescrub_url}`} /> )}
              {companyData.linkedin_url && ( <LinkedinIcon url={`${companyData.linkedin_url}`} /> )}
              {companyData.glassdoor_url && ( <GlassdoorIcon url={`${companyData.glassdoor_url}`} /> )}
              {companyData.capterra_url && ( <CapterraIcon url={`${companyData.capterra_url}`} /> )}
              {companyData.gartner_url && ( <GartnerIcon url={`${companyData.gartner_url}`} /> )}
              {companyData.g2_url && ( <G2Icon url={`${companyData.g2_url}`} /> )}
          </div>
      </Col>
    </Row>
  )
}

export default CompanyAboutCard;