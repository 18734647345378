import React, { useState, useEffect, useCallback } from 'react';
import { getHeadcount } from "../../services/companies";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const HeadcountChart = ({ companyId }) => {
    const [chartData, setChartData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            const response = await getHeadcount({ companyId: companyId });

            if (response.length > 0) {
                const processData = (data) => {
                    // Create an object to store headcount by date and source
                    const headcountByDateAndSource = {};

                    data.forEach(entry => {
                        const date = new Date(entry.date).toISOString().split('T')[0];
                        const source = entry.source;

                        if (!headcountByDateAndSource[date]) {
                            headcountByDateAndSource[date] = {};
                        }

                        headcountByDateAndSource[date][source] = entry.headcount;
                    });

                    // Extract labels (dates) and datasets (source-wise headcounts)
                    const labels = Object.keys(headcountByDateAndSource).sort();
                    const sources = new Set(data.map(entry => entry.source));
                    const datasets = Array.from(sources).map(source => {
                        return {
                            label: source,
                            data: labels.map(date => headcountByDateAndSource[date][source] !== undefined ? headcountByDateAndSource[date][source] : null),
                            fill: false,
                            borderColor: source === 'LinkedIn' ? 'blue' : 'green', // Different color for each source
                            tension: 0.1,
                            spanGaps: true // This ensures gaps are spanned
                        };
                    });

                    return { labels, datasets };
                };

                setChartData(processData(response));
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoaded(true);
        }
    }, [companyId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            {chartData.labels ? (
                <Line
                    data={chartData}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                                font: {
                                    family: "Geist"
                                }
                            },
                            title: {
                                display: false,
                                text: 'Company Headcount Over Time',
                            },
                            datalabels: {
                                display: false,
                            },
                            tooltip: {
                                bodyFont: {
                                    family: "Geist"
                                }
                            }
                        },
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'month',
                                    tooltipFormat: 'D MMM YYYY',
                                },
                                grid: {
                                    display: false
                                },
                                title: {
                                    display: false,
                                    text: 'Date',
                                },
                                ticks: {
                                    font: {
                                        family: "Geist"
                                    }
                                }
                            },
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: 'Headcount',
                                    font: {
                                        family: "Geist"
                                    }
                                },
                                ticks: {
                                    font: {
                                        family: "Geist"
                                    }
                                }
                            },
                        },
                    }}
                />
            ) : isLoaded === false ? (
                <p>Loading data...</p>
            ) : (
              <p>No data</p>
            )}
        </div>
    );
};

export default HeadcountChart;
