import './Spinner.css';

const InlineSpinner = ({ className="" }) => {
    return (
      <span className={`spinner-border spinner-border-sm ${className}`} role="status">
        <span className="sr-only">Loading...</span>
      </span>
    )
};

export default InlineSpinner;