import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './PageWrapper.css';
import Container from "react-bootstrap/Container";

const ColumnPageWrapper = ({children, title}) => {
    return (
        <div id="grid-container">
            <Header />
                <div id="page-content">
                    <Container fluid="md" className="column-container bg-light">
                        {children}
                    </Container>
                </div>
            <Footer />
        </div>
    );
};

export default ColumnPageWrapper;