// This page serves purely to test out components
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Combobox from "../../components/FormFields/Combobox";

const Sandbox = () => {
  return (
    <PageWrapper title="Sandbox">
      <Combobox />
    </PageWrapper>
  )
}

export default Sandbox;