import { useEffect } from 'react';

const useScrollToError = (ref, errorMessage) => {
    useEffect(() => {
        if (errorMessage && ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        }
    }, [errorMessage, ref]);
}

export default useScrollToError;
