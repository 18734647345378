import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import './GenericTable.css';
import CardShimmer from "../LoadingAnimations/CardShimmer";
import Combobox from "../FormFields/Combobox";

const GenericTable = ({
  columns,
  data,
  page,
  totalPages,
  onChangePage,
  isLoading = false,
  tableClassName = "",
  footerClassName = "",
  filters = [],
  hideEmptyCols = false
}) => {
  const [filterValues, setFilterValues] = useState({});
  const [filteredData, setFilteredData] = useState(data);
  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    let filtered = data;

    Object.keys(filterValues).forEach((key) => {
      if (filterValues[key]) {
        filtered = filtered.filter(item => item[key] === filterValues[key]);
      }
    });

    setFilteredData(filtered);
  }, [filterValues, data]);

  useEffect(() => {
    if (hideEmptyCols) {
      const dataKeys = new Set(data.flatMap(Object.keys));
      const nonEmptyColumns = columns.filter(column =>
        !dataKeys.has(column.field) ||
        filteredData.some(item => item[column.field] !== null && item[column.field] !== undefined && item[column.field] !== '')
      );
      setVisibleColumns(nonEmptyColumns);
    } else {
      setVisibleColumns(columns);
    }
  }, [filteredData, columns, hideEmptyCols]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      onChangePage(newPage);
    }
  };

  const getColumnOptions = (field) => {
    const uniqueOptions = [...new Set(data.map(item => item[field]))];
    return uniqueOptions.map(option => ({ value: option, label: option ? option.toString() : '' }));
  };

  const handleFilterChange = (field, value) => {
    setFilterValues(prevValues => ({
      ...prevValues,
      [field]: value
    }));
  };

  const TableContainer = ({ children }) => (
    <div className="table-responsive table-nowrap">
      <Table className={`mb-0 generic-table ${tableClassName}`}>
        <thead className="small text-uppercase">
          <tr>
            {visibleColumns.map(column => <th className={column.className || ''} key={column.header}>{column.header}</th>)}
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </Table>
    </div>
  );

  if (isLoading) {
    return (
      <TableContainer>
        <tr>
          <td className="loading" colSpan={visibleColumns.length}><CardShimmer heightPixels={80} paddingPercent={10} lines={1} /></td>
        </tr>
      </TableContainer>
    );
  }

  return (
    <>
      <div className="filters-container">
        {filters.map(filter => (
          <Combobox
            key={filter}
            options={getColumnOptions(filter)}
            selectedItem={filterValues[filter] || ''}
            setSelectedItem={(value) => handleFilterChange(filter, value)}
            placeholder={`Filter by ${filter}`}
            className="mb-3 me-3"
            width={'150px'}
          />
        ))}
      </div>
      <TableContainer>
        {filteredData.length === 0 ? (
          <tr className="align-middle"><td colSpan={visibleColumns.length}>No results</td></tr>
        ) : (
          filteredData.map((item, index) => (
            <tr className="align-middle" key={item.id || index}>
              {visibleColumns.map((column, colIndex) => (
                <td key={colIndex} className={column.className || ''}>{column.render ? column.render(item) : item[column.field]}</td>
              ))}
            </tr>
          ))
        )}
      </TableContainer>
      <div className={`pagination-container ${footerClassName}`}>
        {page > 1 && (
          <Button variant="unstyled" className="me-3" onClick={() => handlePageChange(page - 1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        )}
        <div className="page-info">Page {page} of {totalPages}</div>
        {page < totalPages && (
          <Button variant="unstyled" className="ms-3" onClick={() => handlePageChange(page + 1)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        )}
      </div>
    </>
  );
};

export default GenericTable;
