import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const RequireLogin = ({ children, redirect = false }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (!isAuthenticated && redirect) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate, redirect]);

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return null;
  }

};

export default RequireLogin;