import React from 'react';
import Form from "react-bootstrap/Form";

const FileInputField = ({ label, id, onChange }) => {
  return (
    <Form.Group>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <Form.Control type="file" id={id} onChange={onChange} />
    </Form.Group>
  );
};

export default FileInputField;
