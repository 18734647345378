import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './PageWrapper.css';
import Container from "react-bootstrap/Container";

const PageWrapper = ({children, title}) => {
    return (
        <div id="grid-container">
            <Header />
                <div id="page-content">
                    <Container fluid="md" className="my-4">
                        <h1 id="page-title">{title}</h1>
                        { title &&
                            <hr className="mt-0 mb-4 hr-title" />
                        }
                        <div>
                            {children}
                        </div>
                    </Container>
                </div>
            <Footer />
        </div>
    );
};

export default PageWrapper;