import React, { useState, useRef, useEffect } from 'react';
import {useLocation, useParams} from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import FloatingInputField from "../../components/FormFields/FloatingInputField"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DealsTable from "../../components/DealsTable/DealsTable";

const DealListPage = () => {
  const { page_num } = useParams();
  const initialPageNum = page_num !== undefined ? parseInt(page_num, 10) : 1;
  const [startingPage, setStartingPage] = useState(initialPageNum);
  const location = useLocation();

  // Load search query state based on the URL parameter if given
  const searchParams = new URLSearchParams(location.search);
  const initialQuery = searchParams.get('search') || "";
  const searchRef = useRef(initialQuery);
  const [submittedSearchQuery, setSubmittedSearchQuery] = useState(initialQuery);

  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const query = searchParams.get('search') || "";
      searchRef.current.value = query; // Ensures the input shows the current query param
      setSubmittedSearchQuery(query);
  }, [location.search]); // Reacts to changes in the URL search params

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSubmittedSearchQuery(searchRef.current.value);
    setStartingPage(1);
  }

  return (
    <RequireLogin redirect>
      <PageWrapper title="Deals">
        <Form onSubmit={handleSearchSubmit} autoComplete="off">
          <FloatingInputField ref={searchRef} type="text" id="inputSearchQuery" label="Type search and hit enter..." />
        </Form>
        <Row>
          <Col className="mb-3 mb-lg-5">
            <Card className="position-relative table-card">
              <DealsTable pageNum={startingPage} dealsPerPage={10} searchString={submittedSearchQuery} cardFooter={true} />
            </Card>
          </Col>
        </Row>
      </PageWrapper>
    </RequireLogin>
  );
};

export default DealListPage;