import React, { useState, useEffect, useCallback } from 'react';
import {Link} from 'react-router-dom';
import { getReportList, deleteReport, getReportSearchResults } from '../../services/reports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faFileWord, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import IconTooltipButton from "../Buttons/IconTooltipButton";
import DisappearingToast from "../DisappearingToast/DisappearingToast";
import GenericTable from "../GenericTable/GenericTable";

const ReportsTable = (
  {
    pageNum=1,
    reportsPerPage=10,
    dealId=null,
    searchString="",
    cardFooter=false
  }) => {
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [reportData, setReportData] = useState(null);
  const [deletedReportName, setDeletedReportName] = useState("");
  const [showDeletedToast, setShowDeletedToast] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      if(searchString) {
        // If a search query has been submitted, ping the endpoint for search results
        const response = await getReportSearchResults({page: currentPage, per_page: reportsPerPage, query: searchString});
        setReportData(response);
      } else {
        // Otherwise ping a different endpoint for the full report list
        const response = await getReportList({page: currentPage, per_page: reportsPerPage, deal_id: dealId});
        setReportData(response);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, searchString]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setCurrentPage(pageNum);
  }, [searchString]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteReport = async (reportId, reportName) => {
    try {
      await deleteReport(reportId);
      setDeletedReportName(reportName);
      setShowDeletedToast(true);
      // Report deleted successfully, refetch report list
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const total_pages = reportData?.total_pages || 1;
  const current_page = reportData?.current_page || 1;
  const reports = reportData?.reports || [];

  const columns = [
    {
      header: 'Title',
      field: 'name',
      render: (report) => (
      <div className="d-flex align-items-center">
        <Link to={`/report/${report.id}`}>{report.title}</Link>
      </div>
    )},
    {
      header: 'Date',
      field: 'date'
    },
    {
      header: 'User',
      field: 'user_email'
    },
    {
      header: 'Download',
      field: 'download',
      className: 'text-center',
      render: (report) => (
      <>
        { report.docx_url && (
          <a href={report.docx_url}>
            <IconTooltipButton tooltipText="Word" className="py-0 px-1">
              <FontAwesomeIcon icon={faFileWord} />
            </IconTooltipButton>
          </a>
        )}
        { report.pdf_url && (
          <a href={report.pdf_url}>
            <IconTooltipButton tooltipText="PDF" className="py-0 px-1">
              <FontAwesomeIcon icon={faFilePdf} />
            </IconTooltipButton>
          </a>
        )}
        { report.xlsx_url && (
          <a href={report.xlsx_url}>
            <IconTooltipButton tooltipText="Excel" className="py-0 px-1">
              <FontAwesomeIcon icon={faFileExcel} />
            </IconTooltipButton>
          </a>
        )}
      </>
    )},
    {
      header: 'Delete',
      field: 'delete',
      className: 'text-center',
      render: (report) => (
      <IconTooltipButton tooltipText="Delete report" className="py-0 px-1" onClick={() => handleDeleteReport(report.id, report.title)}>
        <FontAwesomeIcon icon={faTrashCan} />
      </IconTooltipButton>
    )},
  ];

  return (
    <>
      <GenericTable
        columns={columns}
        data={reports}
        page={current_page}
        totalPages={total_pages}
        onChangePage={handlePageChange}
        isLoading={!reportData}
        footerClassName={cardFooter ? ("card-footer") : ("")}
      />
      <DisappearingToast title="Deleted!" text={`Deleted: ${deletedReportName}`} show={showDeletedToast} setShow={setShowDeletedToast} />
    </>
  );
};

export default ReportsTable;