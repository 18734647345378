import React, { useState, useEffect } from "react";
import RectangleShimmer from "../LoadingAnimations/RectangleShimmer";

const DealIcon = ({icon, alt = "image", className = "", sizePixels = 100}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    if (!isLoading) {
      setDisplay("block");
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && <RectangleShimmer heightPixels={sizePixels} widthPixels={sizePixels} className={className} />}
      <img src={`${icon ? `/api/files/get/${icon}` : "/img/company_logo_placeholder.svg"}`}
           onLoad={() => setIsLoading(false)}
           alt={alt}
           className={className}
           style={{ width: `${sizePixels}px`, height: `${sizePixels}px`, display: display }}
      />
    </>
  );
}

export default DealIcon;