import React, { useState, useEffect, useCallback } from 'react';
import GenericTable from "../GenericTable/GenericTable";
import {getPeople} from "../../services/companies";
import {PitchbookIcon, SourcescrubIcon} from "../SocialIcons/SocialIcons";
import './PeopleTable.css';

const PeopleTable = (
  {
    companyId
  }) => {
  const [peopleData, setPeopleData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getPeople({companyId: companyId});
        setPeopleData(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      header: 'Name',
      field: 'first_name',
      render: (person) => (
        <span className="social-image d-inline-flex align-items-center">
          {person.source === "PitchBook" ? (
              <PitchbookIcon />
          ) : person.source === "SourceScrub" ? (
            <>
              <SourcescrubIcon />
            </>
          ) : (<></>)}
          {person.first_name} {person.last_name}
        </span>
      )
    },
    {
      header: 'Title',
      field: 'title'
    },
    {
      header: 'Email',
      field: 'email'
    },
    {
      header: 'Phone',
      field: 'phone'
    },
  ];

  return (
    <>
      <GenericTable
        columns={columns}
        data={peopleData}
        page={1}
        totalPages={1}
        onChangePage={null}
        isLoading={!peopleData}
        filters={['source']}
        footerClassName="d-none"
        hideEmptyCols={true}
      />
    </>
  );
};

export default PeopleTable;