import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import "./SettingsPage.css"
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import { uploadTemplate, getTemplate, deleteTemplate, downloadDefaultTemplateURL, downloadTemplateURL } from "../../services/reports";
import {getEmailTemplates, deleteEmailTemplate, duplicateEmailTemplate} from "../../services/emails";
import FileInputField from "../../components/FormFields/FileInputField";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashCan, faFileDownload, faPencil, faCopy} from '@fortawesome/free-solid-svg-icons';
import Spinner from "../../components/LoadingAnimations/Spinner";
import Table from "react-bootstrap/Table";
import IconTooltipButton from "../../components/Buttons/IconTooltipButton";
import EmailInstructionsModal from "../EmailTemplatePage/EmailInstructionsModal";
import { useActivatedFeatures } from "../../contexts/ActivatedFeaturesContext";

const SettingsPage = () => {
  const [currentTemplate, setCurrentTemplate] = useState('');
  const [templateFile, setTemplateFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [showEmailInstructions, setShowEmailInstructions] = useState(false);
  const { activatedFeatures } = useActivatedFeatures();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get('tab') || "word-template";


  const fetchTemplate = async () => {
    try {
      const response = await getTemplate();
      setCurrentTemplate(response.file_key);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchEmailTemplates = async () => {
    try {
      const response = await getEmailTemplates();
      setEmailTemplates(response.templates);
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteEmailTemplate = async (templateId) => {
    try {
      await deleteEmailTemplate(templateId);
      await fetchEmailTemplates();
    } catch (error) {
      console.log(error);
    }
  }

  const handleDuplicateEmailTemplate = async (templateId) => {
    try {
      await duplicateEmailTemplate(templateId);
      await fetchEmailTemplates();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchTemplate();
    setLoadingData(false);
  }, []);

  useEffect(() => {
    if (activatedFeatures.emails === true) {
      fetchEmailTemplates();
    }
  }, [activatedFeatures]);

  const handleTemplateUpload = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('file', templateFile);

    try {
      setSuccessMessage('');
      setErrorMessage('');
      await uploadTemplate(formData);
      await fetchTemplate();
      setSuccessMessage('Template successfully uploaded');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleDeleteTemplate = async (event) => {
    event.preventDefault();

    try {
      await deleteTemplate();
      await fetchTemplate();
    } catch (error) {
      console.log(error);
    }
  }

  if (loadingData) {
      return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
  }

  return (
    <RequireLogin redirect>
      <PageWrapper title="Settings">
        <Tab.Container id="settings" defaultActiveKey={initialTab}>
          <Row>
            <Col md={4} className="mb-3">
              <Card>
                <Card.Body>
                  <Nav variant="pills dark" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="word-template">Word Template</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="report-layouts">Report Templates</Nav.Link>
                    </Nav.Item>
                    { activatedFeatures.emails && (
                      <Nav.Item>
                        <Nav.Link eventKey="email-templates">Email Templates</Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8}>
              <Tab.Content>

                <Tab.Pane eventKey="word-template">
                  <Card>
                    <Card.Header>Word Template</Card.Header>
                    <Card.Body>
                      <Row className="mb-2">
                        <Col sm={5} md={4} lg={3}><h6 className="h6-label">Template in Use</h6></Col>
                        { currentTemplate ? (
                        <Col sm={7} md={8} lg={9}>
                          custom_template.docx
                          <a href={downloadTemplateURL}>
                            <IconTooltipButton tooltipText="Download" className="ms-2 py-0 px-0">
                              <FontAwesomeIcon icon={faFileDownload} />
                            </IconTooltipButton>
                          </a>
                          <IconTooltipButton tooltipText="Delete" className="py-0 px-0" onClick={handleDeleteTemplate}>
                            <FontAwesomeIcon icon={faTrashCan} />
                          </IconTooltipButton>
                        </Col>
                        ) : (
                        <Col sm={7} md={8} lg={9}>
                          default_template.docx
                          <a href={downloadDefaultTemplateURL}>
                            <IconTooltipButton tooltipText="Download" className="ms-2 py-0 px-0">
                              <FontAwesomeIcon icon={faFileDownload} />
                            </IconTooltipButton>
                          </a>
                        </Col>
                        )
                        }
                      </Row>
                      <Row className="mb-3">
                        <Col sm={5} md={4} lg={3}><h6 className="h6-label">Default Template</h6></Col>
                        <Col sm={7} md={8} lg={9}>
                          default_template.docx
                          <a href={downloadDefaultTemplateURL}>
                            <IconTooltipButton tooltipText="Download" className="ms-2 py-0 px-0">
                              <FontAwesomeIcon icon={faFileDownload} />
                            </IconTooltipButton>
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>Upload Custom Template</h6>
                          <p>
                            Document templates are .docx files that dictate the styling (e.g. fonts, formatting, logo) of
                            exported reports. Below you can upload a custom template with your preferred styling.
                            To create a custom template, we recommend downloading our default template and making adjustments to it.
                            Note that adding or changing a custom template will apply to all users in your organisation.
                          </p>
                        </Col>
                      </Row>
                      <Form onSubmit={handleTemplateUpload}>
                        <Row>
                          <FileInputField label="Choose a template file" id="templateFile"
                                            onChange={(e) => setTemplateFile(e.target.files[0])} />
                        </Row>
                        <Row>
                        <Col className="button-message-container mt-3">
                            <Button variant="dark" className="btn-template" type="submit">Upload</Button>
                            {errorMessage && (
                              <div className="error-success-message">
                                  <Alert variant="danger">{errorMessage}</Alert>
                              </div>
                            )}
                            {successMessage && (
                              <div className="error-success-message">
                                  <Alert variant="success">{successMessage}</Alert>
                              </div>
                            )}
                        </Col>
                        </Row>
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>

                <Tab.Pane eventKey="report-layouts">
                  <Card>
                    <Card.Header>Report Templates</Card.Header>
                    <Card.Body>
                      Report Templates define the content (e.g. sections, headings, data, text) that are produced by our
                      automated reports. If you wish to create a new layout, email jai@quantera.io.
                    </Card.Body>
                  </Card>
                </Tab.Pane>

                { activatedFeatures.emails === true && (
                  <Tab.Pane eventKey="email-templates">
                    <Card>
                      <Card.Header>Email Templates</Card.Header>
                      <Card.Body>
                        <p>
                          Email Templates are pre-defined layouts for emails that include both static and dynamic text.
                          Emails are generated by taking a report as input and using its content to dynamically populate
                          different sections of the email. You can view, edit and delete your templates below, or create
                          a new one.
                        </p>
                        <Button variant="dark" onClick={() => navigate('/email-template')} className="me-2">Create Template</Button>
                        <Button variant="secondary" onClick={() => setShowEmailInstructions(true)}>Instructions</Button>
                        <EmailInstructionsModal show={showEmailInstructions} onHide={() => setShowEmailInstructions(false)} />

                        <Table className="mb-0 mt-3 email-table">
                          <thead className="small text-uppercase">
                          <tr>
                            <th>Template Name</th>
                            <th className="text-center">Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          { emailTemplates.length === 0 ? (
                            <tr className="align-middle" key="1">
                              <td>No templates created</td>
                              <td></td>
                            </tr>
                          ) : emailTemplates.map((template) => (
                            <tr className="align-middle" key={template.id}>
                              <td>
                                {template.name}
                              </td>
                              <td className="text-center">
                                <IconTooltipButton tooltipText="Edit" className="me-1 py-0 px-1" onClick={() => navigate(`/email-template/${template.id}`)}>
                                  <FontAwesomeIcon icon={faPencil} className="small" />
                                </IconTooltipButton>
                                <IconTooltipButton tooltipText="Duplicate" className="me-1 py-0 px-1" onClick={() => handleDuplicateEmailTemplate(template.id)}>
                                  <FontAwesomeIcon icon={faCopy} className="small" />
                                </IconTooltipButton>
                                <IconTooltipButton tooltipText="Delete" className="py-0 px-1" onClick={() => handleDeleteEmailTemplate(template.id)}>
                                  <FontAwesomeIcon icon={faTrashCan} className="small" />
                                </IconTooltipButton>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>

                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                )}

              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

      </PageWrapper>
    </RequireLogin>
  );
}

export default SettingsPage;