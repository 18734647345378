import React, {useRef, useState} from 'react';
import ReportsTable from "../../components/ReportsTable/ReportsTable";
import {useParams} from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import FloatingInputField from "../../components/FormFields/FloatingInputField"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const ReportListPage = () => {
  const { page_num } = useParams();
  const initialPageNum = page_num !== undefined ? parseInt(page_num, 10) : 1;
  const [startingPage, setStartingPage] = useState(initialPageNum);
  const [submittedSearchQuery, setSubmittedSearchQuery] = useState("");
  const searchRef = useRef(null);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSubmittedSearchQuery(searchRef.current.value);
    setStartingPage(1);
  }

  return (
    <RequireLogin redirect>
      <PageWrapper title="Reports">
        <Form onSubmit={handleSearchSubmit} autoComplete="off">
          <FloatingInputField ref={searchRef} type="text" id="inputSearchQuery" label="Type search and hit enter..." />
        </Form>
        <Row>
          <Col className="mb-3 mb-lg-5">
            <Card className="position-relative table-card">
              <ReportsTable pageNum={startingPage} reportsPerPage={10} searchString={submittedSearchQuery} cardFooter={true} />
            </Card>
          </Col>
        </Row>
      </PageWrapper>
    </RequireLogin>
  );
};

export default ReportListPage;