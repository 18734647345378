import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './ReportBuilderPage.css';
import FloatingInputField from '../../components/FormFields/FloatingInputField';
import FloatingSelectField from "../../components/FormFields/FloatingSelectField";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import { getUrls, buildReport, startJob, queryJob, getReportLayouts } from '../../services/reports';
import { Accordion } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import {useNotifications} from "../../contexts/NotificationsContext";
import CompanyAboutCard from "../../components/CompanyAboutCard/CompanyAboutCard";
import useScrollToError from "../../hooks/useScrollToError";
import QuanteraLoader from "../../components/LoadingAnimations/QuanteraLoader";

const ReportBuilderPage = () => {
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [pitchbookUrl, setPitchbookUrl] = useState('');
  const [sourcescrubUrl, setSourcescrubUrl] = useState('');
  const [crunchbaseUrl, setCrunchbaseUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [glassdoorUrl, setGlassdoorUrl] = useState('');
  const [gartnerUrl, setGartnerUrl] = useState('');
  const [capterraUrl, setCapterraUrl] = useState('');
  const [g2Url, setG2Url] = useState('');
  const [tegusCompanyId, setTegusCompanyId] = useState('');
  const [layoutID, setLayoutID] = useState('');
  const [layoutOptions, setLayoutOptions] = useState([]);
  const [loadingUrls, setLoadingUrls] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [urlResponse, setUrlResponse] = useState({});
  const [reportResponse, setReportResponse] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [progressMessages, setProgressMessages] = useState([]);
  const [progressStatus, setProgressStatus] = useState('processing');
  const [dealId, setDealId] = useState("");
  const [connectorList, setConnectorList] = useState([]);
  const { isAuthenticated } = useContext(AuthContext);
  const { setNotificationsLoaded, fetchNotificationsCount } = useNotifications();
  const inputsRef = useRef(null);
  const buttonsRef = useRef(null);
  const location = useLocation();

  // Get the layouts for the organization that the user is in
  useEffect(() => {
    const fetchLayouts = async () => {
      try {
        const response = await getReportLayouts();
        const layouts = response.layouts.map((layout) => ({
          label: layout.name,
          value: layout.id
        }));
        setLayoutOptions(layouts);
        if (layouts.length > 0) {
          setLayoutID(layouts[0].value); // Set the default layout to the first layout in the list of options
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (isAuthenticated) {
      fetchLayouts();
    }
  }, [isAuthenticated]
  );

  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const name = searchParams.get('companyName');
      const website = searchParams.get('companyWebsite');
      if (name) setCompanyName(name);
      if (website) setCompanyWebsite(website);
  }, []);

  useScrollToError(buttonsRef, errorMessage);

  const scrollToRef = (ref) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }

  useEffect(() => {
    // Scroll to the buttons after clicking Get Inputs, and to the input results after the loading completes
    if (loadingUrls) {
      scrollToRef(buttonsRef);
    } else {
      scrollToRef(inputsRef);
    }
  }, [loadingUrls]);

  const handleGetUrls = async () => {
    setErrorMessage('');
    if (!companyName || !companyWebsite) {
      setErrorMessage("You must enter a company name and website");
      return
    }
    setLoadingUrls(true);
    const data = {
      company_name: companyName,
      company_website: companyWebsite,
      report_layout_id: layoutID,
    };
    try {
      const response = await getUrls(data);
      setUrlResponse(response);
      setPitchbookUrl(response.pitchbook_url || '');
      setSourcescrubUrl(response.sourcescrub_url || '');
      setCrunchbaseUrl(response.crunchbase_url || '');
      setLinkedinUrl(response.linkedin_url || '');
      setGlassdoorUrl(response.glassdoor_url || '');
      setCapterraUrl(response.capterra_url || '');
      setGartnerUrl(response.gartner_url || '');
      setG2Url(response.g2_url || '');
      setConnectorList(response.connectors || []);
      setCompanyId(response.company_id || '');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoadingUrls(false);
    }
  };

  const handleBuildReport = async () => {
    setErrorMessage('');
    setProgressStatus('');
    if (!companyName || !companyWebsite) {
      setErrorMessage("You must enter a company name and website");
      return
    }
    if (Object.keys(urlResponse).length === 0) {
      setErrorMessage("You must run Get Inputs before you can run Build Report");
      return
    }
    if (!layoutID) {
      setErrorMessage("You must select a report layout to build a report");
      return
    }
    setLoadingReport(true);
    let jobResponse;
    const data = {
      company_name: companyName,
      company_website: companyWebsite,
      report_layout_id: layoutID,
      pitchbook_url: pitchbookUrl,
      sourcescrub_url: sourcescrubUrl,
      crunchbase_url: crunchbaseUrl,
      linkedin_url: linkedinUrl,
      glassdoor_url: glassdoorUrl,
      gartner_url: gartnerUrl,
      capterra_url: capterraUrl,
      g2_url: g2Url,
      tegus_company_id: tegusCompanyId
    };

    // First initiate the job and get job_id
    try {
      const response = await startJob(data);
      // Combine the response data with the job_id with the existing data object
      jobResponse = response
      Object.assign(data, response)
    } catch (error) {
      setErrorMessage(error.message);
      setLoadingReport(false);
      return;
    }

    const checkJobStatus = async () => {
      try {
        const response = await queryJob(jobResponse);
        setProgress(response.progress || 0);
        setProgressMessages(response.messages || []);
        setDealId(response.data.deal_id || "");

        if (response.status === "finished") {
          setLoadingReport(false);
          setProgressStatus(response.status);
          setProgress(0);
          setProgressMessages([]);
          setNotificationsLoaded(false);
          fetchNotificationsCount();  // Fetch the notifications count as the report builder will push a notification when build succeeds
        } else if (response.status === "failed" || response.status === "canceled" || response.status === "stopped") {
          setLoadingReport(false);
          setErrorMessage("Failed to build report")
          setProgress(0);
          setProgressMessages([]);
        }
        else {
          timer = setTimeout(checkJobStatus, 3000); // Recursively call checkJobStatus after 3 seconds
        }
      } catch (error) {
        setErrorMessage(error.message);
        setLoadingReport(false);
      }
    };

    let timer = setTimeout(checkJobStatus, 1000); // Initial call to checkJobStatus

    try {
      const response = await buildReport(data);
      setReportResponse(response);
    } catch (error) {
      setErrorMessage(error.message);
      setLoadingReport(false);
      clearTimeout(timer);
      setProgress(0);
      setProgressMessages([]);
    }
  };

  const InputLink = ({ url, prefix="" }) => {
    url = `${prefix}${url}`
    const normalizedUrl = /^(http:|https:)/.test(url) ? url : `http://${url}`;
    const className = `input-link${url ? '' : ' input-link-disabled'}`;
    return (
      <Link to={normalizedUrl} className={className} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faArrowUpRightFromSquare}></FontAwesomeIcon>
      </Link>
    );
  };

  return (
    <RequireLogin redirect>
      <PageWrapper title="Report Builder">
        <Card className="mb-3">
          <Card.Header>
            Instructions
          </Card.Header>
          <Card.Body>
            <ol className="mb-0 fw-light lh-16">
              <li>Select the template you wish to use for your report.</li>
              <li>Enter the name and website URL for the company, then click <kbd>Get Inputs</kbd>.</li>
              <li>Click <kbd>Build Report</kbd> - it takes approximately 1 minute to run.</li>
              <li>During the report build, you can continue to browse the website and will receive a notification when it is complete.</li>
            </ol>
          </Card.Body>
        </Card>
        <div>
          <FloatingSelectField id="selectReportLayout" label="Report Layout" options={layoutOptions} onChange={(e) => setLayoutID(e.target.value)} value={layoutID} />
          <FloatingInputField id="inputCompanyName" type="text" label="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
          <FloatingInputField id="inputCompanyWebsite" type="text" label="Company Website" value={companyWebsite} onChange={(e) => setCompanyWebsite(e.target.value)} />
          {Object.keys(urlResponse).length > 0 && (
            <div ref={inputsRef}>
              {connectorList.includes("pitchbook") && (
              <div className="flexbox-full-width">
                <InputLink url={pitchbookUrl} />
                <FloatingInputField className="flex-fill-horizontal" id="inputPitchbook" type="text" label="PitchBook URL" value={pitchbookUrl} onChange={(e) => setPitchbookUrl(e.target.value)} />
              </div>
              )}
              {connectorList.includes("sourcescrub") && (
              <div className="flexbox-full-width">
                <InputLink url={sourcescrubUrl} />
                <FloatingInputField className="flex-fill-horizontal" id="inputSourcescrub" type="text" label="SourceScrub URL" value={sourcescrubUrl} onChange={(e) => setSourcescrubUrl(e.target.value)} />
              </div>
              )}
              {connectorList.includes("crunchbase") && (
              <div className="flexbox-full-width">
                <InputLink url={crunchbaseUrl} />
                <FloatingInputField className="flex-fill-horizontal" id="inputCrunchbase" type="text" label="Crunchbase URL" value={crunchbaseUrl} onChange={(e) => setCrunchbaseUrl(e.target.value)} />
              </div>
              )}
              {connectorList.includes("linkedin") && (
              <div className="flexbox-full-width">
                <InputLink url={linkedinUrl} />
                <FloatingInputField className="flex-fill-horizontal" id="inputLinkedin" type="text" label="LinkedIn URL" value={linkedinUrl} onChange={(e) => setLinkedinUrl(e.target.value)} />
              </div>
              )}
              {connectorList.includes("glassdoor") && (
              <div className="flexbox-full-width">
                <InputLink url={glassdoorUrl} />
                <FloatingInputField className="flex-fill-horizontal" id="inputGlassdoor" type="text" label="Glassdoor URL" value={glassdoorUrl} onChange={(e) => setGlassdoorUrl(e.target.value)} />
              </div>
              )}
              {connectorList.includes("gartner") && (
              <div className="flexbox-full-width">
                <InputLink url={gartnerUrl} />
                <FloatingInputField className="flex-fill-horizontal" id="inputGartner" type="text" label="Gartner URL" value={gartnerUrl} onChange={(e) => setGartnerUrl(e.target.value)} />
              </div>
              )}
              {connectorList.includes("capterra") && (
              <div className="flexbox-full-width">
                <InputLink url={capterraUrl} />
                <FloatingInputField className="flex-fill-horizontal" id="inputCapterra" type="text" label="Capterra URL" value={capterraUrl} onChange={(e) => setCapterraUrl(e.target.value)} />
              </div>
              )}
              {connectorList.includes("g2") && (
              <div className="flexbox-full-width">
                <InputLink url={g2Url} />
                <FloatingInputField className="flex-fill-horizontal" id="inputG2" type="text" label="G2 URL" value={g2Url} onChange={(e) => setG2Url(e.target.value)} />
              </div>
              )}
              {connectorList.includes("tegus") && (
              <div className="flexbox-full-width">
                <InputLink url={tegusCompanyId} />
                <FloatingInputField className="flex-fill-horizontal" id="inputTegus" type="text" label="Tegus Company ID" value={tegusCompanyId} onChange={(e) => setTegusCompanyId(e.target.value)} />
              </div>
              )}
            </div>
          )}

          { (companyId && !loadingUrls) && (
            <div className="report-builder-company-profile mb-3">
              <CompanyAboutCard companyId={companyId} showHeader={true} />
            </div>
          )}

          {progressStatus !== "finished" && (
            <div ref={buttonsRef}>
              <Button type="button" variant="dark" className="me-2 mb-3" onClick={handleGetUrls} disabled={loadingUrls}>
                Get Inputs
              </Button>

              <Button type="button" variant="dark" className="me-2 mb-3" onClick={handleBuildReport} disabled={loadingReport}>
                Build Report
              </Button>
            </div>
          )}

          {loadingUrls && (
            <div className="report-builder-company-profile text-center">
              <QuanteraLoader sizePixels={70} />
              Building company profile...
            </div>
          )}

          {errorMessage && (
            <div className="report-alert report-error">
              <Alert variant="danger">{errorMessage}</Alert>
            </div>
          )}

          {loadingReport && (
            <>
            <ProgressBar variant="dark" striped animated className="loading-bar" now={progress} label={`${progress}%`} />
            <Accordion className="progress-messages mt-3" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="progress-messages-header">Build Progress Log</Accordion.Header>
                <Accordion.Body>
                  <ul className="list-unstyled">
                    <li key="0">Building report... this may take up to one minute</li>
                    {progressMessages.length > 0 && (
                      progressMessages.map((message, index) => (
                        <li key={index+1}>{message}</li>
                      ))
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </>
          )}

          {Object.keys(urlResponse).length === 1 && !(loadingUrls || loadingReport || errorMessage || progressStatus === "finished") && (
            <div>
              <strong>No inputs to check, report is ready to build 🚀</strong>
            </div>
          )}

          {progressStatus === "finished" && reportResponse.id && (
            <>
              <h3>Your report is ready! 💪</h3>
              <Link to={`/report/${reportResponse.id}`}>
                <Button variant="secondary" className="me-2 mt-3">
                  View Report
                </Button>
              </Link>
            </>
          )}
          { dealId && (
            <Link to={`/deal/${dealId}`}>
              <Button variant="outline-dark" className="me-2 mt-3">
                <FontAwesomeIcon icon={faArrowRight} className="me-1" /> Go to Deal Page
              </Button>
            </Link>
          )}
        </div>
      </PageWrapper>
    </RequireLogin>
  );
};

export default ReportBuilderPage;