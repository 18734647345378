import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import NewsList from "../../components/NewsList/NewsList";
import Card from "react-bootstrap/Card";
import NewsCategoryToggle from "../../components/NewsList/NewsCategoryToggle";
import {useParams} from "react-router-dom";
import {getDeal} from "../../services/companies";

const NewsPage = () => {
  const { dealId } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let initialCategory = searchParams.get('category') || null;
  const validCategories = [null, "funding", "financial", "acquisition", "executive", "award", "event", "general"]
  if (!validCategories.includes(initialCategory)) initialCategory = null;
  const [category, setCategory] = useState(initialCategory);

  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    const getCompanyName = async () => {
      const response = await getDeal(dealId);
      setCompanyName(response.name);
    }
    if (dealId) getCompanyName();
  }, [])

  return (
    <RequireLogin redirect>
      <PageWrapper title={ companyName ? (`News: ${companyName}`) : ('News')}>
        <Card className="p-3">
          <Card.Body>
            <NewsCategoryToggle
              btnClassName="small"
              category={category}
              setCategory={setCategory}
              className="mb-4"
            />
            <NewsList perPage={5} showPagination={true} category={category} dealId={dealId} />
          </Card.Body>
        </Card>
      </PageWrapper>
    </RequireLogin>
  )
}

export default NewsPage;