import React, {createContext, useState, useContext, useEffect} from 'react';
import {getActivatedFeatures} from "../services/user";
import {AuthContext} from "./AuthContext";

const ActivatedFeaturesContext = createContext();

export const useActivatedFeatures = () => useContext(ActivatedFeaturesContext);

export const ActivatedFeaturesProvider = ({ children }) => {
  const [activatedFeatures, setActivatedFeatures] = useState({});
  const [loadingActivatedFeatures, setLoadingActivatedFeatures] = useState(true);
  const { isAuthenticated } = useContext(AuthContext);

  const fetchActivatedFeatures = async () => {
    try {
      if (isAuthenticated) {
        setLoadingActivatedFeatures(true);  // Start loading
        const response = await getActivatedFeatures();
        setActivatedFeatures(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingActivatedFeatures(false); // End loading
    }
  }

  useEffect(() => {
    fetchActivatedFeatures();
  }, [isAuthenticated]);

  const value = {
    activatedFeatures,
    setActivatedFeatures,
    loadingActivatedFeatures
  };

  return <ActivatedFeaturesContext.Provider value={value}>{children}</ActivatedFeaturesContext.Provider>;
};