import React, {useEffect, useState} from "react";

const QuanteraLoader = ({sizePixels=70}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    if (!isLoading) {
      setDisplay("inline-block");
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && <div style={{width: `${sizePixels}px`, height: `${sizePixels}px`, display: "inline-block"}} className="company-loader"></div>}
      <img src="/img/quantera-loader-boomerang.gif"
           onLoad={() => setIsLoading(false)}
           alt="Loading..."
           className="company-loader"
           style={{ width: `${sizePixels}px`, height: `${sizePixels}px`, display: display }}
      />
    </>
  )
};

export default QuanteraLoader;