import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import IconTooltipButton from "../Buttons/IconTooltipButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBook,
  faChevronLeft,
  faChevronRight,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import {getNews} from "../../services/news";
import {getDealFromCompany} from "../../services/companies";
import './NewsList.css';
import CardShimmer from "../LoadingAnimations/CardShimmer";
import Button from "react-bootstrap/Button";
import EmailGeneratorModal from "../EmailGeneratorModal/EmailGeneratorModal";

const NewsList = (
  {
    category = null,
    perPage = 5,
    dealId = null,
    onePerCompany = false,
    showMoreAt = null,
    showPagination = false,
    className = ""
  }) => {
  const [newsData, setNewsData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [expanded, setExpanded] = useState(false);

  // State variables for email generator
  const [showEmailGenerator, setShowEmailGenerator] = useState(false);
  const [emailCompanyId, setEmailCompanyId] = useState("");
  const [emailCompanyName, setEmailCompanyName] = useState("");
  const [emailDealId, setEmailDealId] = useState("");
  const [emailNewsId, setEmailNewsId] = useState("");

  const prevCategoryRef = useRef(category);
  const navigate = useNavigate();

  const fetchNews = async () => {
    const response = await getNews({
      page: currentPage,
      category: category,
      per_page: perPage,
      deal_id: dealId,
      pretty_date: true,
      one_per_company: onePerCompany
    })
    setNewsData(response);
  }

  const navigateToDeal = async (companyId) => {
    const dealData = await getDealFromCompany(companyId);
    navigate(`/deal/${dealData.id}`);
  }

  useEffect(() => {
    if (category !== prevCategoryRef.current) {
      if (currentPage === 1) {
        fetchNews();
      } else {
        setCurrentPage(1);
        prevCategoryRef.current = category;
      }
    } else {
      fetchNews();
    }
  }, [currentPage, category]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleShowEmailModal = async (companyName, companyId, newsId) => {
    setEmailNewsId(newsId);
    setEmailCompanyId(companyId);
    setEmailCompanyName(companyName);
    const dealData = await getDealFromCompany(companyId);
    setEmailDealId(dealData.id);
    setShowEmailGenerator(true);
  }

  if (!newsData) {
    return (<div className="news-item ps-3"><CardShimmer heightPixels={80} paddingPercent={10} lines={1} /></div>)
  }

  const total_pages = newsData?.total_pages || 1;
  const current_page = newsData?.current_page || 1;
  const news = newsData?.news || [];
  const displayNews = expanded || showMoreAt == null ? news : news.slice(0, showMoreAt);

  return (
    <div className={className}>
      {news.length === 0 ? (
        <div className="news-item ps-3 mb-3">No news</div>
      ) : (
        displayNews.map((newsItem) => (
          <div key={newsItem.id} className="news-item ps-3">
            <div className="news-item-title d-flex justify-content-between align-items-center mb-2">
              <span className="fw-medium">
                <a href={newsItem.url} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                  {newsItem.company_name} - {newsItem.title}
                </a>
              </span>
              <span className="flex-shrink-0 ms-3">
                <IconTooltipButton onClick={() => handleShowEmailModal(newsItem.company_name, newsItem.company_id, newsItem.id)} tooltipText="Email company" className="p-0"><FontAwesomeIcon icon={faEnvelope} className="small" /></IconTooltipButton>
                { !dealId && (
                  <>
                    <IconTooltipButton tooltipText="Deal page" className="p-0" onClick={() => navigateToDeal(newsItem.company_id)}><FontAwesomeIcon icon={faBook} className="small" /></IconTooltipButton>
                  </>
                ) }
              </span>
            </div>
            <p className="news-item-content small">
              <span className="date">{newsItem.date}</span> { !category && (<><span className="date">{newsItem.category}</span></>)} <span className="date">{newsItem.publisher}</span> {newsItem.summary}
            </p>
          </div>
        ))
      )}
      {showMoreAt != null && news.length > showMoreAt && (
        <div className="text-start mt-3">
          <Button variant="outline" className="py-1" onClick={toggleExpanded}>
            <span className="small">{expanded ? "Show less" : "Show more"}</span>
          </Button>
        </div>
      )}
      { showPagination && (
        <div className="text-center bg-light py-2 small">
          { current_page > 1 && (
            <Button onClick={() => setCurrentPage(current_page - 1)} variant="unstyled" className="me-3">
              <span className="small"><FontAwesomeIcon icon={faChevronLeft} /></span>
            </Button>
          )}
          Page {current_page} of {total_pages}
          { current_page < total_pages && (
            <Button onClick={() => setCurrentPage(current_page + 1)} variant="unstyled" className="ms-3">
              <span className="small"><FontAwesomeIcon icon={faChevronRight} /></span>
            </Button>
          )}
        </div>
      ) }
      <EmailGeneratorModal
        show={showEmailGenerator}
        onHide={() => setShowEmailGenerator(false)}
        companyName={emailCompanyName}
        companyId={emailCompanyId}
        dealId={emailDealId}
        newsId={emailNewsId}
      />
    </div>
  );
};

export default NewsList;