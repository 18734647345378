import React, { useState, useContext, useEffect } from 'react';
import './RegisterPage.css'
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import FloatingInputField from "../../components/FormFields/FloatingInputField";
import { register } from '../../services/user';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../../contexts/AuthContext';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';


const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [authKey, setAuthKey] = useState('');
    const [registerError, setRegisterError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to the home page if the user is authenticated
        if (isAuthenticated) {
            navigate('/');
        }
    }, []);

    const handleRegister = async (event) => {
        event.preventDefault(); // prevent page refresh
        const data = {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            auth_key: authKey
        };

        setRegisterError('');
        if (!email || !password) {
            setRegisterError('Email and password must be set');
            return;
        }
        if (!authKey) {
            setRegisterError('Valid authorization key must be provided');
            return;
        }

        try {
            const response = await register(data);
            setIsAuthenticated(true);
            setSuccessMessage('Registered successfully, you will be redirected in 3 seconds');
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (error) {
          setRegisterError(error.message);
        }
    }

    return (
        <PageWrapper title="">
            <Row>
                <Col sm={10} md={9} lg={6} className="mx-auto">
                    <Card className="border-0 my-5">
                        <Card.Body className="p-4 p-sm-5">
                            <Card.Title className="text-center mb-4 fs-5">Sign Up</Card.Title>
                            <p className="mb-4 text-muted text-center">
                                Registration is restricted to authorized email addresses and requires an authorization key.
                            </p>
                            <Form onSubmit={handleRegister}>
                                <Row>
                                    <Col md={6}>
                                        <FloatingInputField type="text" label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </Col>
                                    <Col md={6}>
                                        <FloatingInputField type="text" label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </Col>
                                </Row>
                                <FloatingInputField type="email" label="Email *" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <FloatingInputField type="password" label="Password *" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <FloatingInputField type="text" label="Authorization Key *" value={authKey} onChange={(e) => setAuthKey(e.target.value)} />
                                <Button variant="dark" className="btn-register text-uppercase" type="submit">
                                    Register New User
                                </Button>
                            </Form>
                            {registerError && (
                                <>
                                    <hr className="my-4" />
                                    <div className="register-message">
                                        <Alert variant="danger">{registerError}</Alert>
                                    </div>
                                </>
                            )}
                            {successMessage && (
                                <>
                                    <hr className="my-4" />
                                    <div className="register-message">
                                        <Alert variant="success">{successMessage}</Alert>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default RegisterPage;