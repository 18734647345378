import React from 'react';
import GenericModal from "../../components/GenericModal/GenericModal";

const EmailInstructionsModal = ({ show, onHide }) => (
  <GenericModal show={show} onHide={onHide} title="Email Template Instructions" className="small">
    <>
      <p>Our email generator collects data from company reports, recent news and live web searches and feeds this to an AI agent to dynamically populate different sections of an email.</p>
      <p>The template is a simple text skeleton of the email. Dynamic sections are denoted using square brackets [], with plain english inside the brackets to explain what information we would like to populate.</p>
      <p>A very basic example is given below. Use the options in the sidebar to select and populate these dynamic elements.</p>
      <pre style={{whiteSpace: 'pre-wrap'}} className="mb-0">
        <code>
          <p>
            Recipient address: [COMPANY: primary_contact_email]
          </p>
          <p>Dear [COMPANY: primary_contact_first_name],</p>
          <p>I came across [COMPANY: name] in my research and love what you've built, in particular [AI: mention something notable that happened to the company recently in the news].</p>
          <p>Would love to find some time to connect.</p>
          <p>
            Best,<br />
            [USER: first_name]
          </p>
        </code>
      </pre>
    </>
  </GenericModal>
);

export default EmailInstructionsModal;