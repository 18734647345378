import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ReportContainer from "../../components/ReportContainer/ReportContainer";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import Spinner from "../../components/LoadingAnimations/Spinner";
import { deleteReport, getReport } from "../../services/reports";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrashCan,
    faFileWord,
    faFileExcel,
    faFilePdf,
    faEnvelope,
    faPencil,
    faPlus,
    faPenToSquare,
    faBook
} from '@fortawesome/free-solid-svg-icons';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useActivatedFeatures } from "../../contexts/ActivatedFeaturesContext";
import Card from "react-bootstrap/Card";

const ReportPage = () => {
    const { id } = useParams();
    const [loadingReport, setLoadingReport] = useState(true)
    const [reportResponse, setReportResponse] = useState({})
    const { activatedFeatures } = useActivatedFeatures();
    const navigate = useNavigate();

    // Use the `id` query parameter to fetch the report with the specific ID
    useEffect(() => {
        const fetchReport = async () => {
            try {
                const response = await getReport(id);
                setReportResponse(response);
            } catch (error) {
                // console.log(error);
                navigate('/404')
            } finally {
                setLoadingReport(false);
            }
        };
        fetchReport();
        }, [id]
    );

    const handleDeleteReport = async (reportId) => {
        try {
            await deleteReport(reportId);
            // Report deleted successfully, go to report list page
            navigate('/reports')
        } catch (error) {
            console.log(error);
        }
    };

    if (loadingReport) {
      return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
    }

    return (
        <RequireLogin redirect>
          <PageWrapper title={reportResponse.title}>
              <div className="px-3">
                  <Row className="mb-3">
                      <Col md={12}>
                          { reportResponse.docx_url && (
                              <a href={reportResponse.docx_url} className="report-link">
                                  <Button variant="dark" className="btn-md me-2 mb-1">
                                      <FontAwesomeIcon icon={faFileWord} className="me-1" /> Word
                                  </Button>
                              </a>
                          )}
                          { reportResponse.pdf_url && (
                              <a href={reportResponse.pdf_url} className="report-link">
                                  <Button variant="dark" className="btn-md me-2 mb-1">
                                      <FontAwesomeIcon icon={faFilePdf} className="me-1" /> PDF
                                  </Button>
                              </a>
                          )}
                          { reportResponse.xlsx_url && (
                              <a href={reportResponse.xlsx_url} className="report-link">
                                  <Button variant="dark" className="btn-md me-2 mb-1">
                                      <FontAwesomeIcon icon={faFileExcel} className="me-1" /> Excel
                                  </Button>
                              </a>
                          )}
                          <Button variant="secondary" type="button" className="btn-md me-2 mb-1" onClick={() => navigate(`/report/edit/${id}`)}>
                              <FontAwesomeIcon icon={faPencil} className="me-1" /> Edit
                          </Button>
                          { (activatedFeatures.emails && reportResponse.deal_id) && (
                              <Button variant="secondary" type="button" className="btn-md me-2 mb-1" onClick={() => navigate(`/deal/${reportResponse.deal_id}?action=emails`)}>
                                  <FontAwesomeIcon icon={faEnvelope} className="me-1" /> Generate Email
                              </Button>
                          )}
                          <Button variant="danger" className="btn-md mb-1" onClick={() => handleDeleteReport(reportResponse.id)}>
                              <FontAwesomeIcon icon={faTrashCan} className="me-1" /> Delete
                          </Button>
                      </Col>
                  </Row>
              </div>

              <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <ul className="list-unstyled email-list">
                                {reportResponse.deal_id && (<li><FontAwesomeIcon icon={faBook} className="email-icon" /> Deal Page: <Link to={`/deal/${reportResponse.deal_id}`}>Link</Link></li>)}
                                <li><FontAwesomeIcon icon={faPlus} className="email-icon" /> Created: {reportResponse.date} by {reportResponse.user_email}</li>
                                {reportResponse.last_modified && (<li><FontAwesomeIcon icon={faPenToSquare} className="email-icon" /> Last Edited: {reportResponse.last_modified} by {reportResponse.last_modified_user}</li>)}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
              </Row>

              <ReportContainer>
                  {reportResponse.content}
              </ReportContainer>
          </PageWrapper>
        </RequireLogin>
    );
};

export default ReportPage;