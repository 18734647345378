import React from 'react';
import Combobox from "../FormFields/Combobox";

const NewsCategoryToggle = ({category, setCategory, className = "", width = "250px"}) => {

  const categoryOptions = [
    {label: "All", value: null},
    {label: "Funding Rounds", value: "funding"},
    {label: "Financial Updates", value: "financial"},
    {label: "Acquisitions", value: "acquisition"},
    {label: "Executive Changes", value: "executive"},
    {label: "Company Awards", value: "award"},
    {label: "Events & Conferences", value: "event"},
    {label: "General News", value: "general"},
  ]

  return (
    <div className={`d-flex flex-wrap align-items-center ${className}`}>
      <div className="me-3 fw-medium">Select category:</div>
      <Combobox
        selectedItem={category}
        setSelectedItem={setCategory}
        placeholder="Select category"
        options={categoryOptions}
        width={width}
        searchPlaceholder="Search categories..."
      />
    </div>
  )
}

export default NewsCategoryToggle;