import React, { useState, useEffect, useCallback } from 'react';
import GenericTable from "../GenericTable/GenericTable";
import { getFinancials } from "../../services/companies";
import {PitchbookIcon} from "../SocialIcons/SocialIcons";

const FinancialsTable = ({ companyId }) => {
  const [financialsData, setFinancialsData] = useState([]);

  const metricField = {
      header: 'Metric',
      field: 'metric',
      render: (data) => (<span className="social-image d-inline-flex align-items-center"><PitchbookIcon /> {data.metric}</span>)
    }
  const [columns, setColumns] = useState([metricField]);

  const metricLabels = {
    currency: "Currency",
    revenue: "Revenue",
    revenue_growth_yoy: "YoY Revenue Growth",
    ebitda: "EBITDA",
    ebit: "EBIT",
    gross_margin: "Gross Margin",
    net_margin: "Net Margin"
  };

  const metrics = Object.keys(metricLabels);

  function formatPeriod(period, year, month) {
    if (period === "Fiscal Year") {
      return `FY ${year}`;
    } else if (period === "Year") {
      return `LTM ${year}-${String(month).padStart(2, '0')}`;
    } else if (period === "Half Year") {
      return `HY ${year}-${String(month).padStart(2, '0')}`;
    } else if (period === "Quarter") {
      return `Q ${year}-${String(month).padStart(2, '0')}`;
    }
    return `${period} ${year}-${String(month).padStart(2, '0')}`;
  }

  function transposeData(apiResponse) {
    const transposedData = [];

    const periods = apiResponse.map(item => ({
      period: formatPeriod(item.period, item.period_end_year, item.period_end_month),
      data: item
    }));

    const periodColumns = periods.map(period => ({
      header: period.period,
      field: period.period
    }));
    setColumns([metricField, ...periodColumns]);

    metrics.forEach(metric => {
      const entry = { metric: metricLabels[metric] || metric };

      periods.forEach(period => {
        let value = period.data[metric];
        if (metric === "revenue_growth_yoy" && value !== null && value !== undefined) {
          value = `${Math.round(value * 100)}%`;
        }
        entry[period.period] = value;
      });

      transposedData.push(entry);
    });

    // Return filtered data, where the transposedData is not empty/null across all periods
    return transposedData.filter(row =>
      periods.some(period => row[period.period] !== null && row[period.period] !== undefined)
    );
  }

  const fetchData = useCallback(async () => {
    try {
      const response = await getFinancials({ companyId: companyId });
      setFinancialsData(transposeData(response));
    } catch (error) {
      console.log(error);
    }
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <GenericTable
        columns={columns}
        data={financialsData}
        page={1}
        totalPages={1}
        onChangePage={null}
        footerClassName="d-none"
        hideEmptyCols={true}
      />
    </>
  );
};

export default FinancialsTable;
