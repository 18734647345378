import React, { createContext, useState, useEffect } from 'react';
import Spinner from '../components/LoadingAnimations/Spinner';
import { checkAuth } from "../services/user";

const AuthContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
});

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const response = await checkAuth();
        setIsAuthenticated(response.authenticated);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };