import React from 'react';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import './EmailStatusToggle.css';

const EmailsStatusToggle = ({viewSentOnly, setViewSentOnly, setStartingPage, className=""}) => {

  const handleToggleSentView = (sentValue) => {
    setStartingPage(1);
    setViewSentOnly(sentValue);
  }

  return (
    <ButtonGroup className={`email-status-toggle ${className}`}>
      <ToggleButton
        id="unsent" type="radio" variant="outline-dark" name="radio" value={0} checked={viewSentOnly === 0}
        onChange={(e) => handleToggleSentView(Number(e.currentTarget.value))}
      >
        Unsent
      </ToggleButton>
      <ToggleButton
        id="sent" type="radio" variant="outline-dark" name="radio" value={1} checked={viewSentOnly === 1}
        onChange={(e) => handleToggleSentView(Number(e.currentTarget.value))}
      >
        Sent
      </ToggleButton>
      <ToggleButton
        id="all" type="radio" variant="outline-dark" name="radio" value={-1} checked={viewSentOnly === -1}
        onChange={(e) => handleToggleSentView(Number(e.currentTarget.value))}
      >
        All mail
      </ToggleButton>
    </ButtonGroup>
  )

}

export default EmailsStatusToggle;