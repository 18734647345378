import React, { useState, useContext, useEffect } from 'react';
import './LoginPage.css';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import FloatingInputField from "../../components/FormFields/FloatingInputField";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { login } from '../../services/user';
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from '../../contexts/AuthContext';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';


const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        // Redirect to the home page if the user is authenticated
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);


    const handleLogin = async (event) => {
        event.preventDefault(); // prevent page refresh
        const data = {
            email: email,
            password: password
        };

        setLoginError('');
        if (!email || !password) {
            setLoginError('Both email and password are required');
            return;
        }

        try {
            const response = await login(data);
            setIsAuthenticated(true);
            navigate('/');
        } catch (error) {
          setLoginError(error.message);
        }
    }

    return (
        <PageWrapper title="">
            <Row>
                <Col sm={10} md={9} lg={6} className="mx-auto">
                    <Card className="border-0 my-5">
                        <Card.Body className="p-4 p-sm-5">
                            <Card.Title className="text-center mb-4 fs-5">Sign In</Card.Title>
                            <Form onSubmit={handleLogin}>
                                <FloatingInputField type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <FloatingInputField type="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                <Button variant="dark" className="btn-login text-uppercase" type="submit">
                                    Sign in
                                </Button>
                            </Form>
                            <hr className="my-4" />
                            <div className="mb-2">
                                <Link to="/register">
                                    <Button variant="secondary" className="btn-login text-uppercase" type="button">
                                        <FontAwesomeIcon icon={faUserPlus} /> Sign Up
                                    </Button>
                                </Link>
                            </div>
                            {loginError && (
                                <div className="login-message">
                                    <Alert variant="danger">{loginError}</Alert>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default LoginPage;