import httpClient from './httpClient';

const baseUrl = process.env.REACT_APP_BACKEND_ORIGIN;
const emailsUrl = '/api/emails';

let eventSource = null;

export const initializeEmailJob = async (data) => {
  try {
    const response = await httpClient.post(`${emailsUrl}/job`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to run email job');
    }
  }
};

export const queryJob = async (jobId) => {
  try {
    const response = await httpClient.get(`${emailsUrl}/job?job_id=${jobId}`)
    return response.data
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to get job');
    }
  }
};

export const startGenerateEmailStream = (dealId, jobId, emailTemplateId, reportId, emailId, onMessageCallback, onErrorCallback) => {
  let emailIdParsed = null
  let reportIdParsed = null
  if (emailId !== "") emailIdParsed = emailId
  if (reportId !== "") reportIdParsed = reportId

  const url = `${baseUrl}${emailsUrl}/stream-new-email?deal_id=${encodeURIComponent(dealId)}&job_id=${encodeURIComponent(jobId)}&report_id=${encodeURIComponent(reportIdParsed)}&email_template_id=${encodeURIComponent(emailTemplateId)}&email_id=${encodeURIComponent(emailIdParsed)}`;

  if (eventSource) {
    eventSource.close();
  }

  eventSource = new EventSource(url, { withCredentials: true });

  eventSource.onmessage = event => {
    const newPart = JSON.parse(event.data);
    onMessageCallback(newPart);
  };

  eventSource.onerror = () => {
    onErrorCallback();
    eventSource.close();
  };
};

export const stopGenerateEmailStream = () => {
  if (eventSource) {
    eventSource.close();
    eventSource = null;
  }
};

export const getEmailTemplates = async (
  newsEventTemplatesOnly = false,
  excludeNewsEventTemplates = false
) => {
  try {
    const response = await httpClient.get(
      `${emailsUrl}/templates?newsevent_only=${newsEventTemplatesOnly}&exclude_newsevent=${excludeNewsEventTemplates}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to get templates');
    }
  }
};

export const getEmailTemplate = async (id) => {
  try {
    const response = await httpClient.get(`${emailsUrl}/template/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to get template');
    }
  }
};

export const saveEmailTemplate = async (data) => {
  try {
    const response = await httpClient.post(`${emailsUrl}/template`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to save template');
    }
  }
};

export const deleteEmailTemplate = async (id) => {
  try {
    const response = await httpClient.delete(`${emailsUrl}/template/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to save template');
    }
  }
};


export const duplicateEmailTemplate = async (templateId) => {
  try {
    const response = await httpClient.post(`${emailsUrl}/template/duplicate/${templateId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to duplicate template');
    }
  }
};


export const getEmail = async (id) => {
  try {
    const response = await httpClient.get(`${emailsUrl}/email/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to get email');
    }
  }
};


export const deleteEmail = async (emailId) => {
  try {
    const response = await httpClient.delete(`${emailsUrl}/email/${emailId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete email');
  }
};

export const saveEmail = async (emailId, data) => {
  try {
    const response = await httpClient.post(`${emailsUrl}/email/${emailId}`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to save email');
    }
  }
};

export const toggleSentStatus = async (emailId) => {
  try {
    const response = await httpClient.post(`${emailsUrl}/email/${emailId}/sent`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to toggle sent status');
    }
  }
};

export const getEmailList = async ({ page = 1, per_page = 25, sent=-1, deal_id=null}) => {
  try {
    const response = await httpClient.get(`${emailsUrl}/all?page=${page}&per_page=${per_page}&sent=${sent}&deal_id=${deal_id}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch email list');
  }
};

export const getEmailSearchResults = async ({ page = 1, per_page = 25, sent=-1, query=""}) => {
  try {
    const response = await httpClient.get(`${emailsUrl}/search?query=${query}&page=${page}&per_page=${per_page}&sent=${sent}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch email search results');
  }
};


export const translateEmail = async (emailVersionId, data) => {
  try {
    const response = await httpClient.post(`${emailsUrl}/translate/${emailVersionId}`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to translate email');
    }
  }
};