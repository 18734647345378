import './Spinner.css';

const InlineGrower = () => {
    return (
      <span className="spinner-grow spinner-grow-sm" role="status">
        <span className="sr-only">Loading...</span>
      </span>
    )
};

export default InlineGrower;