import httpClient from './httpClient';

const baseUrl = process.env.REACT_APP_BACKEND_ORIGIN;
const reportsUrl = '/api/reports';

export const getUrls = async (data) => {
  try {
    const response = await httpClient.post(`${reportsUrl}/urls`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to fetch URLs');
    }
  }
};

export const buildReport = async (data) => {
  try {
    const response = await httpClient.post(`${reportsUrl}/report`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to build report');
    }
  }
};

export const startJob = async (data) => {
  try {
    const response = await httpClient.post(`${reportsUrl}/job`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to initiate job');
    }
  }
};

export const queryJob = async (data) => {
  try {
    const response = await httpClient.get(`${reportsUrl}/job`, { params: data });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch job status');
  }
};

export const getReportLayouts = async () => {
  try {
    const response = await httpClient.get(`${reportsUrl}/layouts`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch layouts');
  }
};

export const uploadTemplate = async (data) => {
  try {
    const response = await httpClient.post(`${reportsUrl}/template`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Error uploading template file');
    }
  }
};

export const getTemplate = async () => {
  try {
    const response = await httpClient.get(`${reportsUrl}/template`);
    return response.data;
  } catch (error) {
    throw new Error('Error retrieving document template');
  }
};

export const deleteTemplate = async (data) => {
  try {
    const response = await httpClient.delete(`${reportsUrl}/template`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting document template');
  }
};

export const downloadTemplateURL = `${baseUrl}${reportsUrl}/download-template`;

export const downloadDefaultTemplateURL = `${baseUrl}${reportsUrl}/download-default-template`;

export const getReport = async (id) => {
  try {
    const response = await httpClient.get(`${reportsUrl}/report/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch report');
  }
};

export const getReportList = async ({ page = 1, per_page = 25, deal_id=null}) => {
  try {
    const response = await httpClient.get(`${reportsUrl}/all?page=${page}&per_page=${per_page}&deal_id=${deal_id}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch report list');
  }
};

export const getReportSearchResults = async ({ page = 1, per_page = 25, query=""}) => {
  try {
    const response = await httpClient.get(`${reportsUrl}/search?query=${query}&page=${page}&per_page=${per_page}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch report search results');
  }
};

export const deleteReport = async (reportId) => {
  try {
    const response = await httpClient.delete(`${reportsUrl}/report/${reportId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete report');
  }
};


export const updateReport = async (data) => {
  try {
    const response = await httpClient.post(`${reportsUrl}/report/edit`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Error updating report');
    }
  }
};