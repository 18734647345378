import httpClient from './httpClient';

const companiesUrl = '/api/companies';

export const getCompanyAbout = async (companyId) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/about/${companyId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('Failed to fetch company');
    }
  }
};

export const getDeal = async (dealId) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/deal/${dealId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to fetch deal');
    }
  }
};


export const getDealList = async ({ page = 1, per_page = 25}) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/deals?page=${page}&per_page=${per_page}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch report list');
  }
};

export const getDealSearchResults = async ({ page = 1, per_page = 25, query=""}) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/deals/search?query=${query}&page=${page}&per_page=${per_page}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch report search results');
  }
};

export const deleteDeal = async (dealId) => {
  try {
    const response = await httpClient.delete(`${companiesUrl}/deal/${dealId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('Failed to delete deal');
    }
  }
};

export const getDealFromCompany = async (companyId) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/deal-from-company/${companyId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('Failed to get deal');
    }
  }
}

export const getFinancials = async ({ companyId, source = null}) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/financials/${companyId}?source=${source}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch financials');
  }
};

export const getTransactions = async ({ companyId, source = null, type = null}) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/transactions/${companyId}?source=${source}&type=${type}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch transactions');
  }
};

export const getPeople = async ({ companyId, source = null}) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/people/${companyId}?source=${source}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch people');
  }
};

export const getEmployeeReviews = async ({ companyId, source = null}) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/employee-reviews/${companyId}?source=${source}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch employee reviews');
  }
};

export const getHeadcount = async ({ companyId, source = null}) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/headcount/${companyId}?source=${source}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch headcount');
  }
};

export const getCustomerReviews = async ({ companyId, source = null}) => {
  try {
    const response = await httpClient.get(`${companiesUrl}/customer-reviews/${companyId}?source=${source}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch customer reviews');
  }
};