import React from 'react';
import Form from "react-bootstrap/Form";
import './FormFields.css';

const FloatingSelectField = ({ label, id, onChange, value, options = [] }) => {
  // Default option in case the options array is empty
  if (!options.length) {
    options = [{
      "label": "No options",
      "value": ""
    }];
  }

  const optionElements = options.map(option => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  return (
    <Form.Group className="form-floating mb-3">
      <Form.Select id={id} value={value} onChange={onChange}>
        {optionElements}
      </Form.Select>
      <Form.Label htmlFor={id}>{label}</Form.Label>
    </Form.Group>
  );
};

export default FloatingSelectField;