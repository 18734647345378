import React, {createContext, useState, useContext, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {getNotificationsCount} from "../services/user";
import {AuthContext} from "./AuthContext";

const NotificationsContext = createContext();

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }) => {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);
  const location = useLocation(); // Get the current location object
  const { isAuthenticated } = useContext(AuthContext);

  const fetchNotificationsCount = async () => {
    try {
      if (isAuthenticated) {
        const response = await getNotificationsCount();
        setNotificationsCount(response.count);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchNotificationsCount();
    }, [isAuthenticated]
  );

  useEffect(() => {
    // Set notificationsLoaded to false every time a new page loads
    setNotificationsLoaded(false);
    }, [location]
  );

  const value = {
    notificationsCount,
    setNotificationsCount,
    notificationsLoaded,
    setNotificationsLoaded,
    fetchNotificationsCount
  };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};