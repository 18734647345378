import React, {useState, useEffect, useRef} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import Spinner from "../../components/LoadingAnimations/Spinner";
import { updateReport, getReport } from "../../services/reports";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import MarkdownEditor from "../../components/MarkdownEditor/MarkdownEditor";
import Alert from "react-bootstrap/Alert";
import InlineSpinner from "../../components/LoadingAnimations/InlineSpinner";
import CtrlSaveListener from "../../components/EventListeners/CtrlSaveListener";
import DisappearingToast from "../../components/DisappearingToast/DisappearingToast";

const ReportEditorPage = () => {
    const { id } = useParams();
    const [loadingReport, setLoadingReport] = useState(true)
    const [reportResponse, setReportResponse] = useState({})
    const [reportMarkdown, setReportMarkdown] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [runningSave, setRunningSave] = useState(false);
    const [showSaveToast, setShowSaveToast] = useState(false);
    const navigate = useNavigate();
    const ref = useRef(null);
    const saveButtonRef = useRef(null);

    // Use the `id` query parameter to fetch the report with the specific ID
    useEffect(() => {
        const fetchReport = async () => {
            try {
                const response = await getReport(id);
                setReportResponse(response);
                setReportMarkdown(response.content);
            } catch (error) {
                // console.log(error);
                navigate('/404')
            } finally {
                setLoadingReport(false);
            }
        };
        fetchReport();
        }, [id]
    );

    useEffect(() => {
        if (reportMarkdown !== "" && ref.current) {
            // Set markdown once page is fully mounted
            ref.current.setMarkdown(reportMarkdown);
        }
    }, [reportMarkdown, ref.current]);

    const handleSaveReport = async () => {
        setRunningSave(true);
        setErrorMessage("");
        setSuccessMessage("");
        const data = {
            id: id,
            markdown: ref.current?.getMarkdown()
        }
        try {
            const response = await updateReport(data);
            setReportMarkdown(data.markdown);
            setSuccessMessage("Report successfully updated");
            setShowSaveToast(true);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setRunningSave(false);
        }
    }

    if (loadingReport) {
      return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
    }

    return (
        <RequireLogin redirect>
            <PageWrapper title={reportResponse.title}>
            <Container>
                <Row className="mb-3">
                    <Col md={7}>
                        <Button variant="dark" type="button" className="btn-md me-2 mb-1" onClick={() => navigate(`/report/${id}`)}>
                            <FontAwesomeIcon icon={faArrowLeft} className="me-1" /> Back to Report
                        </Button>
                        <CtrlSaveListener saveButtonRef={saveButtonRef} onSaveHandler={handleSaveReport} />
                        <Button ref={saveButtonRef} variant="dark" type="button" className="btn-md mb-1" onClick={() => handleSaveReport()}>
                            { runningSave ? (
                                <>
                                    <InlineSpinner className="me-1" /> Saving...
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faSave} className="me-1" /> Save
                                </>
                            )}
                        </Button>
                        <DisappearingToast title="Report saved!" text="Your changes have been saved" show={showSaveToast} setShow={setShowSaveToast} />
                        {errorMessage && (
                            <div className="error-success-message ms-2">
                                <Alert variant="danger">{errorMessage}</Alert>
                            </div>
                        )}
                        {successMessage && (
                            <div className="error-success-message ms-2">
                                <Alert variant="success">{successMessage}</Alert>
                            </div>
                        )}
                    </Col>
                    <Col md={5} className="d-flex align-items-center justify-content-md-end justify-content-sm-start mt-md-0 mt-3">
                        <span className="text-end">Generated on {reportResponse.date} by {reportResponse.user_email}.</span>
                    </Col>
                </Row>
            </Container>

            <Row className="mb-4">
                <Col>
                    <Card className="mb-3 mb-xl-0">
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>Report Editor</div>
                            </div>
                        </Card.Header>
                            <Card.Body className="py-0 px-0">
                                <Row className="mx-0">
                                    <Col className="email-editor px-0">
                                        <MarkdownEditor ref={ref} showBlockType={true} showImages={true} showTables={true} />
                                    </Col>
                                </Row>
                            </Card.Body>
                    </Card>
                </Col>
            </Row>
            </PageWrapper>
        </RequireLogin>
    );
};

export default ReportEditorPage;