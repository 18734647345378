// A modal used to display a small model with a confirmation button to carry out an event
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmationModal({ show, onHide, title, body, onConfirm }) {
  return (
    <Modal className="d-flex align-items-center" size="sm" show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title as="h6">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="small">
        {body}
      </Modal.Body>
      <Modal.Footer className="py-2">
        <Button variant="secondary" onClick={onHide}>
          <span className="small">Cancel</span>
        </Button>
        <Button variant="dark" onClick={() => {
          onConfirm();
          onHide(); // Optionally close the modal after the action
        }}>
          <span className="small">Confirm</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;