import React, { forwardRef } from 'react';
import Form from "react-bootstrap/Form";
import './FormFields.css';

const FloatingInputField = forwardRef(({ label, value, id, onChange, type, className = "", isDisabled = false, autoComplete="off" }, ref) => {
  return (
    <Form.Group className={`form-floating mb-3 ${className}`}>
      <Form.Control ref={ref} type={type} placeholder={label} value={value} id={id} onChange={onChange} disabled={isDisabled} autoComplete={autoComplete} />
      <Form.Label htmlFor={id}>{label}</Form.Label>
    </Form.Group>
  );
});

export default FloatingInputField;