import React from 'react';
import Form from "react-bootstrap/Form";
import './FormFields.css';

const InputField = ({ label, value, id, onChange, type, isDisabled = false, autoComplete="off", className="mb-3" }) => {
  return (
    <Form.Group className={className}>
      <Form.Label className="small mb-1" htmlFor={id}>{label}</Form.Label>
      <Form.Control type={type} placeholder={label} value={value} id={id} onChange={onChange} disabled={isDisabled} autoComplete={autoComplete} />
    </Form.Group>
  );
};

export default InputField;
