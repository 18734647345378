import React from 'react';
import { Modal } from 'react-bootstrap';

const GenericModal = ({ children, title, show, onHide, size="md", className="" }) => (
  <Modal size={size} show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title as="h5">{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className={className}>
      {children}
    </Modal.Body>
  </Modal>
);

export default GenericModal;
