import React, { useState, useEffect } from 'react';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import './AccountPage.css';
import { getUser, update } from "../../services/user";
import Spinner from "../../components/LoadingAnimations/Spinner";
import InputField from "../../components/FormFields/InputField";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

const AccountPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organization, setOrganization] = useState('');
    const [updateError, setUpdateError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [userData, setUserData] = useState({});
    const [loadingData, setLoadingData] = useState(true)
    const [initials, setInitials] = useState('')

    const getInitials = (first, last) => {
        if (!first && !last) {
            return (<FontAwesomeIcon icon={faUser}/>);
        } else if (!first && last)
            return last[0].toUpperCase();
        else if (first && !last) {
            return first[0].toUpperCase();
        } else {
            return (first[0] + last[0]).toUpperCase();
        }
    }

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await getUser();
                setUserData(response);
                setFirstName(response.first_name);
                setLastName(response.last_name);
                setEmail(response.email);
                setOrganization(response.organization);
                setInitials(getInitials(response.first_name, response.last_name));
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingData(false);
            }
        }
        fetchUser();
    }, []);

    const handleUpdate = async (event) => {
        event.preventDefault(); // prevent page refresh on form submission

        const data = {
            email: email,
            password: password,
            new_password: newPassword,
            first_name: firstName,
            last_name: lastName,
        };

        setSuccessMessage('');
        setUpdateError('');
        if (!password) {
            setUpdateError('Password required to update account details');
            return;
        }
        if (!email) {
            setUpdateError('Email field is required');
            return;
        }

        try {
            const response = await update(data);
            if (data.new_password) {
                setSuccessMessage('Account details updated and password changed');
            } else {
                setSuccessMessage('Account details successfully updated');
            }
            setPassword('');
            setNewPassword('');
            setUserData({
                email: email,
                first_name: firstName,
                last_name: lastName,
            });
            setInitials(getInitials(firstName, lastName));
        } catch (error) {
          setUpdateError(error.message);
        }
    }

    if (loadingData) {
        return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
    }

    return (
        <RequireLogin redirect>
            <PageWrapper title="My Account">
                <Row className="account-page">
                    <Col sm={12} md={4}>
                        <Card className="mb-3 mb-xl-0">
                            <Card.Header>Snapshot</Card.Header>
                                <Card.Body className="text-center">
                                    <div className="avatar-xl">
                                        <span className="avatar-title rounded-circle bg-dark text-white fs-1">
                                            {initials}
                                        </span>
                                    </div>
                                    <ul className="list-unstyled mt-2 mb-0">
                                        <li>{userData.first_name} {userData.last_name}</li>
                                        <li>{userData.email}</li>
                                        <li>{userData.organization}</li>
                                    </ul>
                                </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={8}>
                        <Card className="mb-4">
                            <Card.Header>Edit Details</Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleUpdate}>
                                    <Row className="gx-3">
                                        <Col md={6}>
                                            <InputField type="text" id="inputFirstName" label="First Name"
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)} />
                                        </Col>
                                        <Col md={6}>
                                            <InputField type="text" id="inputLastName" label="Last Name"
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <InputField type="email" id="inputEmail" label="Email Address *"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)} />
                                    </Row>
                                    <Row>
                                        <InputField type="text" id="inputOrganization" label="Organization"
                                                    value={organization} isDisabled={true} />
                                    </Row>
                                    <Row className="gx-3">
                                        <Col md={6}>
                                            <InputField type="password" id="inputPassword" label="Password *"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)} />
                                        </Col>
                                        <Col md={6}>
                                            <InputField type="password" id="inputNewPassword" label="New Password"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="align-items-start button-message-container">
                                        <Button variant="dark" className="btn-update-account" type="submit">Save Changes</Button>
                                        {updateError && (
                                            <div className="error-success-message">
                                                <Alert variant="danger">{updateError}</Alert>
                                            </div>
                                        )}
                                        {successMessage && (
                                            <div className="update-account-message">
                                                <Alert variant="success">{successMessage}</Alert>
                                            </div>
                                        )}
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </PageWrapper>
        </RequireLogin>
    )
}

export default AccountPage;