import React, {useState, useRef, useEffect} from 'react';
import {useParams, useNavigate, Link} from "react-router-dom";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import MarkdownEditor from "../../components/MarkdownEditor/MarkdownEditor";
import '../EmailPage/EmailPage.css';
import FloatingInputField from "../../components/FormFields/FloatingInputField";
import EmailInstructionsModal from "./EmailInstructionsModal";
import { getEmailTemplate, saveEmailTemplate, deleteEmailTemplate } from "../../services/emails";
import Spinner from "../../components/LoadingAnimations/Spinner";
import Alert from "react-bootstrap/Alert";
import FloatingTextAreaField from "../../components/FormFields/FloatingTextAreaField";
import { useActivatedFeatures } from "../../contexts/ActivatedFeaturesContext";
import InlineSpinner from "../../components/LoadingAnimations/InlineSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrashCan, faQuestion, faBackward} from "@fortawesome/free-solid-svg-icons";
import CtrlSaveListener from "../../components/EventListeners/CtrlSaveListener";
import DisappearingToast from "../../components/DisappearingToast/DisappearingToast";
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from "react-bootstrap/ListGroup";
import { OpenAIIcon, GoogleIcon } from "../../components/SocialIcons/SocialIcons";
import useScrollToError from "../../hooks/useScrollToError";
import GenericModal from "../../components/GenericModal/GenericModal";
import EmailTemplateContext from "./EmailTemplateContext";

const EmailTemplatePage = () => {
    const { id } = useParams();
    const ref = useRef(null);
    const saveButtonRef = useRef(null);
    const [templateId, setTemplateId] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [templateInstructions, setTemplateInstructions] = useState("");
    const [templateBody, setTemplateBody] = useState("");
    const [contextInputs, setContextInputs] = useState({
        report: false,
        news: false,
        newsevent: false,
        websearch: false
    });
    const [contextWebSearches, setContextWebSearches] = useState([]);
    const [showEmailInstructions, setShowEmailInstructions] = useState(false);
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [pageTitle, setPageTitle] = useState("New Email Template");
    const [runningSave, setRunningSave] = useState(false);
    const [showSaveToast, setShowSaveToast] = useState(false);
    const [showAIQueryModal, setShowAIQueryModal] = useState(false);
    const [aiQueryError, setAIQueryError] = useState("");
    const { activatedFeatures, loadingActivatedFeatures } = useActivatedFeatures();
    const navigate = useNavigate();
    const aiQueryRef = useRef(null);
    const errorMessageRef = useRef(null);

    // If an ID parameter is given, fetch the relevant template, otherwise we are creating a new template
    useEffect(() => {
        if (!loadingActivatedFeatures && activatedFeatures.emails !== true) {
            navigate('/404');
            return
        }
        if (id !== undefined) {
            const fetchTemplate = async () => {
                try {
                    const response = await getEmailTemplate(id);
                    setTemplateId(id);
                    setTemplateName(response.name);
                    setTemplateInstructions(response.add_instructions);
                    setPageTitle(`Email Template: ${response.name}`);
                    setTemplateBody(response.template);
                    setContextInputs(response.context_inputs);
                    setContextWebSearches(response.context_web_searches);
                } catch (error) {
                    navigate('/404')
                } finally {
                    setLoadingTemplate(false);
                }
            };
            fetchTemplate();
        } else {
            setLoadingTemplate(false);
        }
    }, [id, loadingActivatedFeatures]);

    useEffect(() => {
        if (templateBody !== "" && ref.current) {
            // Set markdown once page is fully mounted
            ref.current.setMarkdown(templateBody);
        }
    }, [templateBody, ref.current]);

    useScrollToError(errorMessageRef, errorMessage);

    const handleSaveTemplate = async() => {
        setRunningSave(true);
        setErrorMessage("");
        setSuccessMessage("");
        const data = {
            id: templateId,
            name: templateName,
            add_instructions: templateInstructions,
            template: ref.current?.getMarkdown(),
            context_inputs: contextInputs,
            context_web_searches: contextWebSearches
        }
        try {
            const response = await saveEmailTemplate(data);
            setTemplateBody(data.template);
            setSuccessMessage("Template successfully saved");
            setTemplateId(response.id);
            setPageTitle(`Email Template: ${templateName}`);
            setShowSaveToast(true);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setRunningSave(false);
        }
    }

    const handleDeleteTemplate = async() => {
        setErrorMessage("");
        setSuccessMessage("");
        try {
            const response = await deleteEmailTemplate(templateId);
            setSuccessMessage("Template deleted, redirecting in 3 seconds");
            setTimeout(() => {
                navigate('/settings?tab=email-templates');
            }, 3000);
        } catch (error) {
            setErrorMessage(error.message);
        }
    }

    const handleInsertMarkdown = (markdownText) => {
        ref.current.focus(null, {defaultSelection: "rootStart", preventScroll: false});
        ref.current.insertMarkdown(markdownText);
    }

    const handleInsertAIQuery = (markdownText) => {
        setAIQueryError("");
        if (markdownText) {
            handleInsertMarkdown(`{AI: ${markdownText}}`);
            setShowAIQueryModal(false);
        } else {
            setAIQueryError("AI prompt cannot be empty");
        }
    }

    if (loadingTemplate) {
      return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
    }

    return (
        <RequireLogin redirect>
            <PageWrapper title={pageTitle}>
                <Row className="mb-1">
                    <Col>
                        <div className="mb-3 ms-2 small"><Link to="/settings?tab=email-templates" className="text-decoration-none"><FontAwesomeIcon icon={faBackward} className="me-2" />Back to Templates</Link></div>
                        <FloatingInputField id="inputTemplateName" type="text" label="Email Template Name" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Card className="mb-3 mb-xl-0">
                            <Card.Header>
                                <div>Email Context Builder</div>
                            </Card.Header>
                            <Card.Body>
                                <EmailTemplateContext
                                  contextInputs={contextInputs}
                                  setContextInputs={setContextInputs}
                                  contextWebSearches={contextWebSearches}
                                  setContextWebSearches={setContextWebSearches}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Card className="mb-3 mb-xl-0">
                            <Card.Header>
                                <div>Email Template Editor</div>
                            </Card.Header>
                                <Card.Body className="py-0 px-0">
                                    <Row className="mx-0">
                                        <Col sm={5} md={3} className="email-sidebar pt-0 pb-3 px-0">
                                            <div className="email-sidebar-header">Insert Dynamic Content</div>
                                            <Accordion className="email-template-accordion">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Company Attributes</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ListGroup className="list-group-flush email-sidebar-list">
                                                            <Button onClick={() => handleInsertMarkdown("{COMPANY: name}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>Company Name</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{COMPANY: hq_city}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>HQ City</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{COMPANY: hq_country}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>HQ Country</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{COMPANY: primary_contact_first_name}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>Contact First Name</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{COMPANY: primary_contact_last_name}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>Contact Last Name</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{COMPANY: primary_contact_email}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>Contact Email</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{COMPANY: primary_contact_title}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>Contact Job Title</ListGroup.Item>
                                                            </Button>
                                                        </ListGroup>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>User Attributes</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ListGroup className="list-group-flush email-sidebar-list">
                                                            <Button onClick={() => handleInsertMarkdown("{USER: first_name}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>First Name</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{USER: last_name}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>Last Name</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{USER: email}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>User Email</ListGroup.Item>
                                                            </Button>
                                                            <Button onClick={() => handleInsertMarkdown("{USER: organization_name}")} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item>Organization Name</ListGroup.Item>
                                                            </Button>
                                                        </ListGroup>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>AI</Accordion.Header>
                                                    <Accordion.Body>

                                                        <ListGroup className="list-group-flush email-sidebar-list">
                                                            <Button onClick={() => {setAIQueryError(""); setShowAIQueryModal(true);}} className="btn-email-sidebar text-start">
                                                                <ListGroup.Item><OpenAIIcon /> Insert an AI query</ListGroup.Item>
                                                            </Button>
                                                        </ListGroup>

                                                        <GenericModal size="lg" show={showAIQueryModal} onHide={() => setShowAIQueryModal(false)} title="Insert an AI query">
                                                          <p>Enter a prompt for the AI below. It will "fill in" the relevant part of the email by following the instructions in your prompt.</p>
                                                          <FloatingTextAreaField className="mb-3" ref={aiQueryRef} id="aiQueryInput" label="Prompt for AI" />
                                                            <Button variant="dark" onClick={() => handleInsertAIQuery(aiQueryRef.current?.value)}>Insert Prompt</Button>
                                                            {aiQueryError && (
                                                                <div className="error-success-message ms-2">
                                                                    <Alert variant="danger">{aiQueryError}</Alert>
                                                                </div>
                                                            )}
                                                        </GenericModal>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Col>

                                        <Col sm={7} md={9} className="email-editor px-0">
                                            <MarkdownEditor ref={ref} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <FloatingTextAreaField id="inputTemplateInstructions" type="text" label="Additional instructions for AI (e.g. formatting instructions, example output, etc...)" value={templateInstructions} onChange={(e) => setTemplateInstructions(e.target.value)} height={100} />
                    </Col>
                </Row>
                <div className="button-message-container">
                    <CtrlSaveListener saveButtonRef={saveButtonRef} onSaveHandler={handleSaveTemplate} />
                    <Button ref={saveButtonRef} variant="dark" className="me-2" onClick={() => handleSaveTemplate()}>
                    { runningSave ? (
                        <>
                            <InlineSpinner className="me-1" /> Saving...
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faSave} className="me-1" /> Save
                        </>
                    )}
                    </Button>
                    <DisappearingToast title="Template saved!" text="Your changes have been saved" show={showSaveToast} setShow={setShowSaveToast} />
                    { templateId !== '' && (
                        <Button variant="danger" className="me-2" onClick={() => handleDeleteTemplate()}>
                            <FontAwesomeIcon icon={faTrashCan} className="me-1" /> Delete
                        </Button>
                    )}
                    <Button variant="secondary" onClick={() => setShowEmailInstructions(true)}>
                        <FontAwesomeIcon icon={faQuestion} className="me-1" /> Help
                    </Button>
                    {errorMessage && (
                        <div ref={errorMessageRef} className="error-success-message ms-2">
                            <Alert variant="danger">{errorMessage}</Alert>
                        </div>
                    )}
                    {successMessage && (
                        <div className="error-success-message ms-2">
                            <Alert variant="success">{successMessage}</Alert>
                        </div>
                    )}
                    <EmailInstructionsModal show={showEmailInstructions} onHide={() => setShowEmailInstructions(false)} />
                </div>
            </PageWrapper>
        </RequireLogin>
    )
}

export default EmailTemplatePage;