import React, { forwardRef } from 'react';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import './IconTooltipButton.css';

const IconTooltipButton = forwardRef(({ tooltipText = "", position="top", onClick, className, children }, ref) => {

  return (
    <OverlayTrigger
      placement={position}
      overlay={
        <Tooltip id={`tooltip-${position}`} className={`icon-tooltip-text`}>
          {tooltipText}
        </Tooltip>
      }
    >
      <Button ref={ref} onClick={onClick} className={className} variant="icon-tooltip">
        {children}
      </Button>
    </OverlayTrigger>
  );
});

export default IconTooltipButton;
