import httpClient from './httpClient';

const filesUrl = '/api/files';

export const downloadFile = async (data) => {
  try {
    const response = await httpClient.get(`${filesUrl}/download`, data);
    return response.data;
  } catch (error) {
    throw new Error('Download failed');
  }
};

export const uploadImage = async (data) => {
  try {
    const response = await httpClient.post(`${filesUrl}/upload-image`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Error uploading image');
    }
  }
};
