import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getEmailList, deleteEmail, getEmailSearchResults, toggleSentStatus } from "../../services/emails";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashCan,
  faCheck,
  faEnvelopeCircleCheck
} from '@fortawesome/free-solid-svg-icons';
import IconTooltipButton from "../../components/Buttons/IconTooltipButton";
import DisappearingToast from "../DisappearingToast/DisappearingToast";
import GenericTable from "../GenericTable/GenericTable";

const EmailsTable = ({
  pageNum = 1,
  dealId = null,
  searchString = "",
  viewSentOnly = 0,
  emailsPerPage = 10,
  cardFooter = false
}) => {
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [emailsData, setEmailsData] = useState(null);
  const [toastActionText, setToastActionText] = useState("");
  const [showToast, setShowToast] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      if (searchString) {
        const response = await getEmailSearchResults({ page: currentPage, per_page: emailsPerPage, sent: viewSentOnly, query: searchString });
        setEmailsData(response);
      } else {
        const response = await getEmailList({ page: currentPage, per_page: emailsPerPage, sent: viewSentOnly, deal_id: dealId });
        setEmailsData(response);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, searchString, viewSentOnly]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setCurrentPage(pageNum);
  }, [searchString, viewSentOnly]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteEmail = async (emailId, emailTitle) => {
    try {
      await deleteEmail(emailId);
      setToastActionText(`Deleted: ${emailTitle}`);
      setShowToast(true);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarkAsSent = async (emailId, emailTitle, emailSent) => {
    let prefix = "";
    try {
      await toggleSentStatus(emailId);
      if (emailSent) prefix = "un"
      setToastActionText(`Marked as ${prefix}sent: ${emailTitle}`);
      setShowToast(true);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const total_pages = emailsData?.total_pages || 1;
  const current_page = emailsData?.current_page || 1;
  const emails = emailsData?.emails || [];

  const columns = [
    {
      header: 'Title',
      field: 'name',
      render: (email) => (
        <Link to={`/email/${email.id}`}>{email.title}</Link>
      )
    },
    {
      header: 'Created',
      field: 'date_created'
    },
    {
      header: 'Last Modified',
      field: 'last_modified'
    },
    {
      header: 'Sent',
      field: 'sent',
      className: 'text-center',
      render: (email) => (
        <>
          {email.sent === true && (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </>
      )
    },
    {
      header: 'Actions',
      field: 'actions',
      className: 'text-center',
      render: (email) => (
        <>
          <IconTooltipButton tooltipText={email.sent ? "Mark as unsent" : "Mark as sent"} onClick={() => handleMarkAsSent(email.id, email.title, email.sent)} className="me-2 py-0 px-2">
            <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="small" />
          </IconTooltipButton>
          <IconTooltipButton tooltipText="Delete" onClick={() => handleDeleteEmail(email.id, email.title)} className="py-0 px-2">
            <FontAwesomeIcon icon={faTrashCan} className="small" />
          </IconTooltipButton>
        </>
      )
    },
  ];

  return (
    <>
      <GenericTable
        columns={columns}
        data={emails}
        page={current_page}
        totalPages={total_pages}
        onChangePage={handlePageChange}
        isLoading={!emailsData}
        footerClassName={cardFooter ? ("card-footer") : ("")}
      />
      <DisappearingToast title="Action" text={toastActionText} show={showToast} setShow={setShowToast} />
    </>
  );
};

export default EmailsTable;