import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import FloatingInputField from "../../components/FormFields/FloatingInputField"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {useActivatedFeatures} from "../../contexts/ActivatedFeaturesContext";
import { useNavigate } from 'react-router-dom';
import EmailsTable from "../../components/EmailsTable/EmailsTable";
import EmailsStatusToggle from "../../components/EmailsTable/EmailsStatusToggle";

const EmailListPage = () => {
  const { page_num } = useParams();
  const initialPageNum = page_num !== undefined ? parseInt(page_num, 10) : 1;
  const [startingPage, setStartingPage] = useState(initialPageNum);
  const [submittedSearchQuery, setSubmittedSearchQuery] = useState("");
  const [viewSentOnly, setViewSentOnly] = useState(0);
  const { activatedFeatures, loadingActivatedFeatures } = useActivatedFeatures();
  const searchRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loadingActivatedFeatures && activatedFeatures.emails !== true) {
        navigate('/404');
    }
  }, [loadingActivatedFeatures]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSubmittedSearchQuery(searchRef.current.value);
    setStartingPage(1);
  }

  return (
    <RequireLogin redirect>
      <PageWrapper title="Emails">
        <EmailsStatusToggle className="mb-3" viewSentOnly={viewSentOnly} setViewSentOnly={setViewSentOnly} setStartingPage={setStartingPage} />
        <Form onSubmit={handleSearchSubmit} autoComplete="off">
          <FloatingInputField ref={searchRef} type="text" id="inputSearchQuery" label="Type search and hit enter..." />
        </Form>
        <Row>
          <Col className="mb-3 mb-lg-5">
            <Card className="position-relative table-card">
              <EmailsTable pageNum={startingPage} searchString={submittedSearchQuery} viewSentOnly={viewSentOnly} cardFooter={true} />
            </Card>
          </Col>
        </Row>
      </PageWrapper>
    </RequireLogin>
  );
};

export default EmailListPage;