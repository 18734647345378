import React, { useState, useEffect, useCallback } from 'react';
import GenericTable from "../GenericTable/GenericTable";
import { getEmployeeReviews } from "../../services/companies";
import { GlassdoorIcon } from "../SocialIcons/SocialIcons";

const EmployeeReviewsTable = ({ companyId }) => {
  const [employeeReviewsData, setEmployeeReviewsData] = useState([]);

  const metricField = {
    header: 'Category',
    field: 'metric',
    render: (data) => (
      <span className="social-image d-inline-flex align-items-center">
        <GlassdoorIcon /> {data.metric}
      </span>
    )
  };

  const [columns, setColumns] = useState([
    metricField,
    { header: "Company", field: 'company' },
    { header: 'Industry Benchmark', field: 'industry_benchmark' }
  ]);

  const metricLabels = {
    total_ratings: "Total Ratings",
    average_rating: "Average Rating",
    business_outlook: "Positive Business Outlook %",
    ceo: "Positive CEO Outlook %",
    recommend_to_friend: "Recommend to Friend %",
    senior_management: "Senior Management",
    compensation_and_benefits: "Compensation and Benefits",
    career_opportunities: "Career Opportunities",
    work_life_balance: "Work Life Balance",
    culture_and_values: "Culture and Values",
    diversity_and_inclusion: "Diversity and Inclusion",
  };

  const percentageMetrics = ['business_outlook', 'ceo', 'recommend_to_friend'];

  const formatValue = (metric, value) => {
    if (value == null) return null;
    if (metric === "total_ratings") return value.toFixed(0);
    if (percentageMetrics.includes(metric)) {
      if (value === -1) {
        return null
      } else {
        return `${(value * 100).toFixed(0)}%`;
      }
    }
    return value.toFixed(2);
  };

  const metrics = Object.keys(metricLabels);

  function transposeData(apiResponse) {
    if (apiResponse == null) return [];

    return metrics
      .map(metric => {
        let companyValue = null;
        let benchmarkValue = null;

        if (metric === "total_ratings" || metric === "average_rating") {
          companyValue = apiResponse?.[metric] ?? null;
        } else if (apiResponse.ratings_by_category !== null) {
          companyValue = apiResponse.ratings_by_category?.[metric] ?? null;
        }

        if (apiResponse.benchmark_ratings_by_category !== null) {
          benchmarkValue = apiResponse.benchmark_ratings_by_category?.[metric] ?? null;
        }

        // If both values are null, filter out this row
        if (companyValue == null && benchmarkValue == null) {
          return null;
        }

        return {
          metric: metricLabels[metric],
          company: formatValue(metric, companyValue),
          industry_benchmark: formatValue(metric, benchmarkValue),
        };
      })
      .filter(row => (row.company !== null || row.industry_benchmark !== null)); // Filter out null rows
  }

  const fetchData = useCallback(async () => {
    try {
      const response = await getEmployeeReviews({ companyId: companyId });
      setEmployeeReviewsData(transposeData(response));
    } catch (error) {
      console.log(error);
    }
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <GenericTable
        columns={columns}
        data={employeeReviewsData}
        page={1}
        totalPages={1}
        onChangePage={null}
        footerClassName="d-none"
        hideEmptyCols={true}
      />
    </>
  );
};

export default EmployeeReviewsTable;
