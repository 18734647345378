import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import GenericModal from "../GenericModal/GenericModal";
import ReactMarkdown from "react-markdown";
import Combobox from "../FormFields/Combobox";
import {translateEmail} from "../../services/emails";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faArrowsRotate} from "@fortawesome/free-solid-svg-icons";
import InlineSpinner from "../LoadingAnimations/InlineSpinner";
import Alert from "react-bootstrap/Alert";

const EmailTranslatorModal = ({ show, onHide, emailVersionId}) => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [translatedEmailId, setTranslatedEmailId] = useState("");
  const [translatedContent, setTranslatedContent] = useState("");
  const navigate = useNavigate();

  const translationOptions = [
    {label: "French", value: "FR"},
    {label: "German", value: "DE"},
    {label: "Italian", value: "IT"}
  ]

  const handleTranslateEmail = async () => {
    setErrorMessage("");
    if (!selectedLanguage) {
      setErrorMessage("You must first select a target language");
      return
    }
    setIsLoading(true);
    try {
      const data = {language: selectedLanguage};
      const response = await translateEmail(emailVersionId, data);
      setTranslatedEmailId(response.email_id);
      setTranslatedContent(response.content);
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false);
    }
  }

  const clearData = () => {
    setSelectedLanguage("");
    setIsLoading(false);
    setErrorMessage("");
    setTranslatedEmailId("");
    setTranslatedContent("");
  }

  const hideModal = () => {
    setErrorMessage("");
    setSelectedLanguage("");
    onHide();
  }

  const handleGoToEmail = () => {
    navigate(`/email/${translatedEmailId}`);
    hideModal();
    clearData();
  }

  return (
    <GenericModal size="xl" show={show} onHide={() => hideModal()} title="Translate Email">

      <div>
        { !translatedEmailId && (
          <>
            <Combobox
              label="Select target language"
              options={translationOptions}
              selectedItem={selectedLanguage}
              setSelectedItem={setSelectedLanguage}
              searchPlaceholder="Filter languages..."
            />

            <Button onClick={() => handleTranslateEmail()} variant="dark" className="ms-3">
              { isLoading ? (
                  <InlineSpinner />
                ) : (
                  <>Translate Email</>
                )
              }
            </Button>
          </>
        )}
      </div>

      {errorMessage && (
        <div className="mt-3">
            <Alert className="py-2 d-inline-block" variant="danger">{errorMessage}</Alert>
        </div>
      )}

      <div className="generated-email-container">
        { translatedContent && (
          <ReactMarkdown>{translatedContent}</ReactMarkdown>
        )}
        { translatedEmailId && (
          <>
            <Button onClick={() => handleGoToEmail()} variant="dark" className="me-3">
              <FontAwesomeIcon icon={faArrowRight} className="me-1" /> Go to New Email
            </Button>
            <Button onClick={() => clearData()} variant="outline-dark" className="me-3">
              <FontAwesomeIcon icon={faArrowsRotate} className="me-1" /> Start New Translation
            </Button>
          </>
        )}
      </div>
    </GenericModal>
  )
};

export default EmailTranslatorModal;