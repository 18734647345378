import React, { useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const DisappearingToast = ({ title, text, show, setShow, timeout=5000 }) => {
  useEffect(() => {
    let timer;
    if (show) {
      // Make the toast disappear after 'timeout' milliseconds (default 5 seconds)
      timer = setTimeout(() => setShow(false), timeout);
    }
    return () => clearTimeout(timer);
  }, [show]);

  return (
    <ToastContainer position="bottom-end" className="p-3 position-fixed">
      <Toast show={show} onClose={() => setShow(false)} animation={true}>
        <Toast.Header closeButton={false}>
          <strong className="me-auto">{title}</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>{text}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default DisappearingToast;