import httpClient from './httpClient';

const newsUrl = '/api/news';

export const getNews = async (
  {
    page = 1,
    per_page = 10,
    deal_id = null,
    category = null,
    pretty_date=false,
    one_per_company = false
  }) => {
  try {
    const response = await httpClient.get(
      `${newsUrl}/?page=${page}&per_page=${per_page}&deal_id=${deal_id}&category=${category}&pretty_date=${pretty_date}&one_per_company=${one_per_company}`
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Error getting news');
    }
  }
}
