import React from 'react';
import { useRef, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import {
    faPlus,
    faPenToSquare,
    faFileInvoice,
    faFile,
    faSave,
    faTrashCan,
    faEnvelopeCircleCheck,
    faComment,
    faBook,
    faLanguage
} from "@fortawesome/free-solid-svg-icons";
import MarkdownEditor from "../../components/MarkdownEditor/MarkdownEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconTooltipButton from "../../components/Buttons/IconTooltipButton";
import './EmailPage.css';
import FloatingTextAreaField from "../../components/FormFields/FloatingTextAreaField";
import { getEmail, saveEmail, deleteEmail, toggleSentStatus } from "../../services/emails";
import Spinner from "../../components/LoadingAnimations/Spinner";
import { useActivatedFeatures } from "../../contexts/ActivatedFeaturesContext";
import DisappearingToast from "../../components/DisappearingToast/DisappearingToast";
import CtrlSaveListener from "../../components/EventListeners/CtrlSaveListener";
import EmailTranslatorModal from "../../components/EmailTranslatorModal/EmailTranslatorModal";

const EmailPage = () => {
    const { id } = useParams();
    const ref = useRef(null);
    const saveButtonRef = useRef(null);
    const commentsSectionRef = useRef(null);
    const [loadingEmail, setLoadingEmail] = useState(true);
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [showTranslatorModal, setShowTranslatorModal] = useState(false);
    const [emailResponse, setEmailResponse] = useState({});
    const [commentInput, setCommentInput] = useState("");
    const [diffMarkdown, setDiffMarkdown] = useState("");
    const [currentVersion, setCurrentVersion] = useState("");
    const [markedAsSent, setMarkedAsSent] = useState(false);
    const [showSaveToast, setShowSaveToast] = useState(false);
    const [showMarkedSentToast, setShowMarkedSentToast] = useState(false);
    const { activatedFeatures, loadingActivatedFeatures } = useActivatedFeatures();
    const navigate = useNavigate();

    const fetchEmail = async () => {
        try {
            const response = await getEmail(id);
            setEmailResponse(response);
            setMarkedAsSent(response.sent);
            setDiffMarkdown(response.versions[0].diff_content);
            setCurrentVersion(response.versions[0].id);
        } catch (error) {
            // console.log(error);
            navigate('/404')
        } finally {
            setLoadingEmail(false);
        }
    };

    useEffect(() => {
        if (!loadingActivatedFeatures && activatedFeatures.emails !== true) {
            navigate('/404');
            return
        }
        fetchEmail();
    }, [id, loadingActivatedFeatures]);

    useEffect(() => {
        if (emailResponse?.versions?.length > 0 && ref.current) {
            // Set ref to current markdown once page is fully mounted
            ref.current.setMarkdown(emailResponse.versions[0].content);
            setDiffMarkdown(emailResponse.versions[0].diff_content);
            setCurrentVersion(emailResponse.versions[0].id);
        }
    }, [emailResponse, ref.current]);

    const handleVersionChange = (versionId, versionMarkdown, versionDiffMarkdown) => {
        setCurrentVersion(versionId)
        const index = emailResponse.versions.findIndex(version => version.id === versionId);
        if (index === -1) {
            console.log("Couldn't find email version");
            return
        }
        ref.current?.setMarkdown(versionMarkdown);
        setDiffMarkdown(versionDiffMarkdown);
        setCommentInput("");
    }

    const handleSaveEmail = async () => {
        const content = ref.current?.getMarkdown();
        const data = {
            content: content,
            comments: commentInput
        }
        try {
            const response = await saveEmail(id, data);
            fetchEmail();  // Refresh the data on the page after saving
            setShowSaveToast(true);
            setCommentInput("");
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteEmail = async () => {
        try {
            await deleteEmail(id);
            // Email deleted successfully, go to email list page
            navigate('/emails')
        } catch (error) {
            console.log(error);
        }
    };

    const handleMarkAsSent = async () => {
        try {
            await toggleSentStatus(id);
            if (markedAsSent === true) {
                setMarkedAsSent(false);
            } else {
                setMarkedAsSent(true);
            }
            setShowMarkedSentToast(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleShowComments = () => {
        setShowCommentInput(true);

        // Wait for the state to update and then scroll to the comments section
        setTimeout(() => {
            if (commentsSectionRef.current) {
                commentsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    }

    if (loadingEmail) {
      return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
    }

    return (
        <RequireLogin redirect>
            <PageWrapper title={emailResponse.title}>
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Body>
                                <ul className="list-unstyled email-list">
                                    <li><FontAwesomeIcon icon={faBook} className="email-icon" /> Deal Page: <Link to={`/deal/${emailResponse.deal_id}`}>Link</Link></li>
                                    <li><FontAwesomeIcon icon={faPlus} className="email-icon" /> Created: {emailResponse.date_created}</li>
                                    <li><FontAwesomeIcon icon={faPenToSquare} className="email-icon" /> Last Edited: {emailResponse.last_modified}</li>
                                    {emailResponse.report_title && (<li><FontAwesomeIcon icon={faFile} className="email-icon" /> Source Report: <Link to={`/report/${emailResponse.report_id}`}>{emailResponse.report_title}</Link></li>)}
                                    <li><FontAwesomeIcon icon={faFileInvoice} className="email-icon" /> Email Template: {emailResponse.template_name ? <Link to={`/email-template/${emailResponse.template_id}`}>{emailResponse.template_name}</Link> : "Unavailable" }</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="mb-3 mb-xl-0">
                            <Card.Header>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>Email Editor</div>
                                    <div>
                                        { markedAsSent && (
                                            <span className="marked-sent-text">Marked as sent</span>
                                        )}
                                        <CtrlSaveListener saveButtonRef={saveButtonRef} onSaveHandler={handleSaveEmail} />
                                        <IconTooltipButton ref={saveButtonRef} tooltipText="Save" onClick={() => handleSaveEmail()} className="me-2 py-0 px-2">
                                          <FontAwesomeIcon icon={faSave} className="small" />
                                        </IconTooltipButton>
                                        <IconTooltipButton tooltipText="Translate" onClick={() => setShowTranslatorModal(true)} className="me-2 py-0 px-2">
                                          <FontAwesomeIcon icon={faLanguage} className="small" />
                                        </IconTooltipButton>
                                        <IconTooltipButton tooltipText="Show/add comments" onClick={() => handleShowComments()} className="me-2 py-0 px-2">
                                          <FontAwesomeIcon icon={faComment} className="small" />
                                        </IconTooltipButton>
                                        <IconTooltipButton tooltipText={markedAsSent ? "Mark as unsent" : "Mark as sent"} onClick={() => handleMarkAsSent()} className="me-2 py-0 px-2">
                                          <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="small" />
                                        </IconTooltipButton>
                                        <IconTooltipButton tooltipText="Delete" onClick={() => handleDeleteEmail()} className="py-0 px-2">
                                          <FontAwesomeIcon icon={faTrashCan} className="small" />
                                        </IconTooltipButton>
                                    </div>
                                </div>
                            </Card.Header>
                                <Card.Body className="py-0 px-0">
                                    <Row className="mx-0">
                                        <Col sm={5} md={3} className="email-sidebar pt-0 pb-3 px-0">
                                            <div className="email-sidebar-header">Version History</div>
                                            <ListGroup className="list-group-flush email-sidebar-list px-3">
                                                { emailResponse.versions.map((version) => (
                                                    <Button key={version.id} onClick={() => handleVersionChange(version.id, version.content, version.diff_content)} className="btn-email-sidebar text-start">
                                                        <ListGroup.Item className={version.id === currentVersion ? "email-sidebar-current d-block" : "d-block"}>
                                                            {version.date_created}<br />
                                                            <span className="small">By {version.user}</span>
                                                        </ListGroup.Item>
                                                    </Button>
                                                )) }
                                            </ListGroup>
                                        </Col>
                                        <Col sm={7} md={9} className="email-editor px-0">
                                            <MarkdownEditor ref={ref} diffMarkdown={diffMarkdown} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                        </Card>
                        { showCommentInput && (
                            <div className="mt-4" ref={commentsSectionRef}>
                                <FloatingTextAreaField label="Add a comment..." id="commentsTextArea" onChange={(e) => setCommentInput(e.target.value)} />
                                <Card className="mt-4">
                                    <Card.Header>Comment History</Card.Header>
                                    <Card.Body>
                                        <ListGroup className="list-group-flush">
                                        { emailResponse.versions.map((version) => (
                                            version.comments && (
                                                <ListGroup.Item key={version.id}>{version.comments}<br />
                                                    <span className="small">{version.date_created} by {version.user}</span>
                                                </ListGroup.Item>
                                            )
                                        ))}
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </div>
                        ) }
                    </Col>
                </Row>
                <DisappearingToast title="Email saved!" text="New version added to version history" show={showSaveToast} setShow={setShowSaveToast} />
                <DisappearingToast title="Action" text={markedAsSent ? "Email marked as sent" : "Email marked as unsent"} show={showMarkedSentToast} setShow={setShowMarkedSentToast} />
                <EmailTranslatorModal show={showTranslatorModal} onHide={() => setShowTranslatorModal(false)} emailVersionId={currentVersion} />
            </PageWrapper>
        </RequireLogin>
    )
}

export default EmailPage;