import httpClient from "./httpClient";

const userUrl = '/api/user';

export const login = async (data) => {
  try {
    const response = await httpClient.post(`${userUrl}/login`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to log in');  // If error format is unexpected fall back to generic message
    }
  }
};

export const register = async (data) => {
  try {
    const response = await httpClient.post(`${userUrl}/register`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to register');
    }
  }
};

export const update = async (data) => {
  try {
    const response = await httpClient.post(`${userUrl}/update`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to update user');
    }
  }
};

export const getUser = async () => {
  try {
    const response = await httpClient.get(`${userUrl}/@me`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to get user data');
  }
};

export const checkAuth = async () => {
  try {
    const response = await httpClient.get(`${userUrl}/authenticated`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to determine authentication');
  }
};

export const logout = async () => {
  try {
    const response = await httpClient.post(`${userUrl}/logout`);
    window.location.href = "/";
    return response.data;
  } catch (error) {
    throw new Error('There was an error logging out')
  }
}

export const getUsage = async ({ days = 30 }) => {
  try {
    const response = await httpClient.get(`${userUrl}/usage?days=${days}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch usage');
  }
};


export const getUsageSummary = async ({ days = 30 }) => {
  try {
    const response = await httpClient.get(`${userUrl}/usage-summary?days=${days}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch usage summary');
  }
};


export const getNotifications = async () => {
  try {
    const response = await httpClient.get(`${userUrl}/notifications`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to get notifications');
  }
};

export const getNotificationsCount = async () => {
  try {
    const response = await httpClient.get(`${userUrl}/notifications-count`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to get notifications count');
  }
};

export const markNotificationsSeen = async () => {
  try {
    const response = await httpClient.post(`${userUrl}/mark-notifications-seen`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to mark notifications as seen');
  }
};


export const getActivatedFeatures = async () => {
  try {
    const response = await httpClient.get(`${userUrl}/activated-features`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to get activated features');
  }
};