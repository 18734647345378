import React, { useState, useEffect, useCallback } from 'react';
import GenericTable from "../GenericTable/GenericTable";
import { getCustomerReviews } from "../../services/companies";
import {CapterraIcon, G2Icon, GartnerIcon} from "../SocialIcons/SocialIcons";
import Combobox from "../FormFields/Combobox";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import './CustomerReviewsTable.css';
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

const CustomerReviewsTable = ({ companyId }) => {
  const [customerReviewsData, setCustomerReviewsData] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionData, setSelectedOptionData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);

  const metricLabels = {
    total_ratings: "Total Ratings",
    average_rating: "Average Rating",
    ease_of_use: "Ease of Use",
    customer_service: "Customer Service",
    functionality: "Functionality",
    value_for_money: "Value for Money",
    likelihood_to_recommend: "Likelihood to Recommend"
  };

  const formatValue = (metric, value) => {
    if (value == null) return null;
    if (metric === "total_ratings") return value.toFixed(0);
    return value.toFixed(1);
  };

  const metrics = Object.keys(metricLabels);

  function processTableData(apiResponse, selectedItemId) {
    let selectedItem = apiResponse.find(item => item.id === selectedItemId);

    if (!selectedItem) {
      return []; // or return a default value if needed
    }

    if (selectedItem?.ratings_by_category && typeof selectedItem?.ratings_by_category === 'object') {
      // Merge field3 properties into the top-level object
      for (let key in selectedItem.ratings_by_category) {
        if (selectedItem.ratings_by_category.hasOwnProperty(key)) {
          selectedItem[key] = selectedItem.ratings_by_category[key];
        }
      }
      delete selectedItem.ratings_by_category;
    }

    setSelectedOptionData(selectedItem);

    return metrics
      .map(metric => {
        const value = selectedItem[metric];

        return {
          metric: metricLabels[metric],
          value: formatValue(metric, value),
          source: selectedItem.source
        };
      })
      .filter(row => (row.value !== null && row.value !== undefined)); // Filter out null rows
  }

  const fetchData = useCallback(async () => {
    try {
      const response = await getCustomerReviews({ companyId: companyId });
      setCustomerReviewsData(response);
      const options = response.map(reviews => {
        const label = `${reviews.source} - ${reviews.product_name}`;
        return {
          label: label,
          value: reviews.id
        }
      })
      setDropdownOptions(options);
      if (options.length > 0) {
        setSelectedOption(options[0].value);
      }
    } catch (error) {
      console.log(error);
    }
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setColumns([
      {
        header: 'Metric',
        field: 'metric',
        render: (data) => (
          <span className="social-image d-inline-flex align-items-center">
            { data.source === "Capterra" ? (
              <>
                <CapterraIcon /> {data.metric}
              </>
            ) : data.source === "Gartner" ? (
              <>
                <GartnerIcon /> {data.metric}
              </>
            ) : data.source === "G2" ? (
                        <>
                <G2Icon /> {data.metric}
              </>
            ) : (<>{data.metric}</>)
            }
          </span>
        )
      },
      {
        header: "Value",
        field: "value"
      }
    ]);
    setTableData(processTableData(customerReviewsData, selectedOption))
  }, [selectedOption])

  return (
    <>
      <Combobox
        options={dropdownOptions}
        selectedItem={selectedOption}
        setSelectedItem={setSelectedOption}
        placeholder={"Select product"}
        searchPlaceholder={"Search products..."}
        className={"mb-3"}
      />
      { selectedOptionData?.source_url && (
        <a href={`${selectedOptionData.source_url}`} target="_blank" rel="noopener noreferrer">
          <Button className="mb-3 ms-2" variant="outline"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className={"me-2"} />Go to source</Button>
        </a>
      )}
      <GenericTable columns={columns} data={tableData} page={1} totalPages={1} footerClassName={"d-none"} />

      { selectedOptionData?.reviews_summary && (
        <div className="customer-reviews-summary mt-4">
          <ReactMarkdown remarkPlugins={[gfm]}>{selectedOptionData.reviews_summary}</ReactMarkdown>
        </div>
      )}
    </>
  );
};

export default CustomerReviewsTable;
